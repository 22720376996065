import React, { useState } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import RegisterForm from "./RegisterForm";
import LoginRegisterLeftContainer from "./LoginRegisterLeftContainer";
import LoginRegisterDivider from "./LoginRegisterDivider";
import LoginForm from "./LoginForm";
import ForgetPasswordForm from "./ForgetPasswordForm";
import { useConfiguration } from "../../context/configuration-context";

import CompanyLogo from "../core/CompanyLogo";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      padding: "0",
      margin: "0",
      display: "block",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  headerInfos: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "92%",
    padding: "10px",
    position: "fixed",
    borderRadius: "25",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
}));

const LoginOrRegister = ({ variant }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const registerMode = variant === "register";
  const forgetMode = variant === "forget-password";
  const [active, setActive] = useState(true);
  const configuration = useConfiguration();
  const declination =
    configuration.declination.indexOf("dcontent") !== -1 ? "light" : "dark";
  const isMobile = window.innerWidth <= 425;

  const displayRightForm = () => {
    if (registerMode && !forgetMode) {
      return <RegisterForm />;
    } else if (forgetMode) {
      return <ForgetPasswordForm />;
    }
    return <LoginForm />;
  };

  return (
    <>
      {active && configuration.sector === "1" && (
        <Box className={classes.headerInfos}>
          <Alert
            severity="info"
            variant="outlined"
            style={{ backgroundColor: "#ebf4a4", border: "2px solid green" }}
            color="success"
            onClose={() => {
              setActive(false);
            }}
          >
            <span style={{ color: "black" }}>
              <b>{t("InfoMsgHomeFirst")}</b>
            </span>
            <span style={{ color: "black" }}>{t("InfoMsgHomeSecond")}</span>
          </Alert>
        </Box>
      )}
      <Container className={classes.root}>
        <Box className={classes.container}>
          {registerMode ? (
            <LoginRegisterLeftContainer
              cardTitle={t("AlreadyHaveAccount")}
              cardRedirect="/login"
              cardBtnLabel={t("Login")}
            />
          ) : (
            <LoginRegisterLeftContainer
              cardTitle={t("DontHaveAccount")}
              cardRedirect="/register"
              cardBtnLabel={t("Register")}
            />
          )}

          <LoginRegisterDivider />

          {displayRightForm()}
          {isMobile && <CompanyLogo declination={declination} />}
        </Box>
      </Container>
    </>
  );
};

export default LoginOrRegister;
