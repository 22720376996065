import React from "react";

import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaStepper from "../../core/stepper/CotroliaStepper";
import CotroliaStepperActions from "../../core/stepper/CotroliaStepperActions";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../../core/input/CotroliaInputLabel";
import CotroliaSelect from "../../core/input/CotroliaSelect";
import AppConstant from "../../../utils/appConstant";
import ContentTwoSided, {
  LeftSide,
  RightSide,
} from "../../core/ContentTwoSided";
import TableTotal from "../../core/TableTotal";
import CotroliaTextField from "../../core/input/CotroliaTextField";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    overflowX: "auto",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(
      0,
    )}px ${theme.spacing(1)}px`,
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableRow-root:not(.MuiTableRow-head):last-of-type .MuiTableCell-root":
      {
        borderBottom: "none",
      },
  },
}));

const StepChoice = ({
  choice,
  setChoice,
  data,
  nextStep,
  steps,
  activeStep,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("InvoiceChoice")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("InvoiceChoiceDesc")}
      </Typography>
      <CotroliaStepper
        steps={steps}
        activeStep={activeStep}
        variant="secondary"
      />

      <Box m={2}>
        <Typography variant="h6" align="center">
          {t("Overview")}
        </Typography>
      </Box>

      <ContentTwoSided>
        <LeftSide>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartType")}
              value={data.partType}
              disabled
            />
          </CotroliaFormControl>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartManufacturer")}
              value={data.manufacturer}
              disabled
            />
          </CotroliaFormControl>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartFrequency")}
              value={data.failureFrequency}
              disabled
            />
          </CotroliaFormControl>
        </LeftSide>
        <RightSide>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("PartDescription")}
              value={data.interDesc}
              disabled
              multiline
              rows={4}
            />
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>

      <Box m={2}>
        <Typography variant="h6" align="center">
          {t("Details")}
        </Typography>
      </Box>

      {/* Lines table */}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("ProductCode")}</TableCell>
              <TableCell>{t("ProductName")}</TableCell>
              <TableCell>{t("TotalHT")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.lines.map((line, idx) => {
              return (
                <TableRow key={idx}>
                  {line.type === "freeline" ? (
                    <TableCell colSpan={3}>
                      <div
                        dangerouslySetInnerHTML={{ __html: line.title }}
                      ></div>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>{line.code}</TableCell>
                      <TableCell>{line.title}</TableCell>
                      <TableCell>{line.total_ht}</TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box m={2} width="100%">
        <TableTotal
          totalHt={data.total_ht || 0}
          totalTva={data.total_tva || 0}
          totalTtc={data.total_ttc || 0}
        />
      </Box>

      <Box m={2}>
        <Typography variant="h6" align="center">
          {t("YourChoice")}
        </Typography>
      </Box>

      <CotroliaFormControl variant="filled" required>
        <CotroliaInputLabel id="choice-label">
          {t("PayementChoice")}
        </CotroliaInputLabel>
        <CotroliaSelect
          id="choice"
          labelId="choice-label"
          value={choice}
          onChange={(e) => {
            setChoice(e.target.value);
          }}
        >
          <MenuItem value={AppConstant.invoiceChoiceCB}>
            {t("InvoiceChoiceCB")}
          </MenuItem>
          <MenuItem value={AppConstant.invoiceChoiceBankTransfer}>
            {t("InvoiceChoiceBankTransfer")}
          </MenuItem>
          <MenuItem value={AppConstant.invoiceChoiceCheque}>
            {t("InvoiceChoiceCheque")}
          </MenuItem>
        </CotroliaSelect>
      </CotroliaFormControl>

      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          color="secondary"
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("Previous")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ArrowForwardIcon />}
          onClick={nextStep}
          disabled={choice === ""}
        >
          {t("Pay")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default StepChoice;
