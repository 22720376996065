import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import LanguageSelector from "./LanguageSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
    },
    bottom: "0",
    padding: ".2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="caption" style={{ opacity: ".5" }}>
        Version {import.meta.env.VITE_VERSION}
      </Typography>
      <LanguageSelector />
    </div>
  );
};

export default Footer;
