import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";

import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import LinkOffIcon from "@material-ui/icons/LinkOff";

import SelectOrAddContact from "../core/SelectOrAddContact";
import AddContactForm from "../core/AddContactForm";
import authService from "../../services/auth.service";
import AppConstant from "../../utils/appConstant";
import { useAuth } from "../../features/user/context/auth-context";
import { Popup, PopupCard } from "../core/Popup";

const useStyle = makeStyles((theme) => ({
  listEntryContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
    position: "relative",
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}px`,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
}));

const ContactEntry = ({ id, lastname, firstname, email, phone }) => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const handleDelete = () => {
    authService
      .deleteContactRole(id, AppConstant.typeContactBilling)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), {
          title: t("BillingContactNotDeleted"),
        });
      });
  };

  const handleEdit = (lastname, firstname, email, phone) => {
    authService
      .updateContact(id, lastname, firstname, email, phone)
      .then(() => window.location.reload())
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), {
          title: t("ContactNotModified"),
        });
      });
    setEditMode(false);
  };

  const handlePermanentDelete = () => {
    authService
      .deleteContact(id)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), { title: t("ContactNotDeleted") });
      });
  };

  return (
    <>
      <Box className={classes.entryContainer}>
        <PersonIcon />
        <Typography variant="body1" className={classes.entryLabel}>
          {lastname + " " + firstname}
        </Typography>
        <Tooltip title={t("EditContact")}>
          <IconButton
            className={classes.entryBtn}
            onClick={() => setEditMode(!editMode)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("UnlinkContact")}>
          <IconButton className={classes.entryBtn} onClick={handleDelete}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {editMode && (
        <Popup>
          <PopupCard
            title={t("EditBillingContact")}
            description={
              t("EditBillingContactDesc") +
              " : " +
              lastname +
              " " +
              firstname +
              " <" +
              email +
              ">"
            }
          >
            <AddContactForm
              lastname={lastname}
              firstname={firstname}
              email={email}
              phone={phone}
              addBtnLabel={t("Modify")}
              handleAdd={handleEdit}
              handleCancel={() => setEditMode(false)}
            />
          </PopupCard>
          <PopupCard
            title={t("DeleteContact")}
            description={
              t("DeleteContactDesc") +
              " : " +
              lastname +
              " " +
              firstname +
              " <" +
              email +
              ">"
            }
            variant="outlined"
            color="secondary"
            action={handlePermanentDelete}
            actionLabel={t("Delete")}
            actionIcon={<DeleteIcon />}
          ></PopupCard>
        </Popup>
      )}
    </>
  );
};

const ProfileBillingContact = ({ contacts }) => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const { user, setUser } = useAuth();

  const [addMode, setAddMode] = useState(false);
  const [contactList, setContactList] = useState(contacts);

  const handleAdd = (id) => {
    authService
      .addContactRole(id, AppConstant.typeContactBilling)
      .then((res) => {
        alert.success("", { title: t("BillingContactAdded") });

        // Update local user informations
        let userCopy = { ...user };
        userCopy.thirdparty.contactList = [
          ...userCopy.thirdparty.contactList,
          res.data,
        ];
        setUser(userCopy);

        // Update local props list
        setContactList([...contactList, res.data]);
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), { title: t("BillingContactNotAdded") });
      });
  };

  const handleCreate = (lastname, firstname, email, phone) => {
    authService
      .createContact(
        lastname,
        firstname,
        email,
        phone,
        AppConstant.typeContactBilling,
      )
      .then((res) => {
        alert.success("", { title: t("BillingContactAdded") });

        // Update local user informations
        let userCopy = { ...user };
        userCopy.thirdparty.contactList = [
          ...userCopy.thirdparty.contactList,
          res.data,
        ];
        setUser(userCopy);

        // Update local props list
        setContactList([...contactList, res.data]);

        setAddMode(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.response.status === 403) {
          alert.error(t("TryAgainLater"), {
            title: t("BillingContactNotAdded403"),
          });
        } else {
          alert.error(t("TryAgainLater"), {
            title: t("BillingContactNotAdded"),
          });
        }
      });
  };

  return (
    <>
      {contactList.length > 0 ? (
        <Box className={classes.listEntryContainer}>
          {contactList.map((contact, index) => (
            <ContactEntry key={index} {...contact} />
          ))}
        </Box>
      ) : (
        <>
          <SelectOrAddContact
            required={false}
            selected=""
            onSelect={handleAdd}
            onClick={() => setAddMode(true)}
            excludeList={contactList}
          />
          {addMode && (
            <AddContactForm
              handleAdd={handleCreate}
              handleCancel={() => setAddMode(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfileBillingContact;
