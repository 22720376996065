import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  InputAdornment,
  TextFieldProps,
} from "@material-ui/core";
import validationService from "../../../services/validation.service";
import CotroliaTextField from "./CotroliaTextField";
import useDebouncedEffect from "use-debounced-effect";

type InputVatProps = TextFieldProps & {
  onValidate: (isVatValid: boolean) => void;
};

const CotroliaVatInput: React.FC<InputVatProps> = (props) => {
  const { t } = useTranslation();
  const [eventCatched, setEventCatched] = useState<
    React.ChangeEvent<HTMLInputElement> | undefined
  >();
  const [vatError, setVatError] = useState<string>();
  const [isLoading, setLoading] = useState(false);

  useDebouncedEffect(
    () => {
      setVatError(undefined);
      if (eventCatched?.target.value) {
        setLoading(true);
        validationService
          .validateVat(eventCatched.target.value)
          .then(() => {
            props.onValidate && props.onValidate(true);
          })
          .catch(() => {
            setVatError(t("VatNotValid"));
            props.onValidate && props.onValidate(false);
          })
          .finally(() => setLoading(false));
      } else if (props.required) {
        setVatError(t("VatRequired"));
        props.onValidate && props.onValidate(false);
      }
    },
    300,
    [eventCatched?.target.value],
  );

  return (
    <CotroliaTextField
      {...props}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setEventCatched(e);
        props.onChange && props.onChange(e);
      }}
      error={!!vatError}
      helperText={vatError}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading && <CircularProgress color="primary" size={24} />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CotroliaVatInput;
