import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaInputLabel from "../../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../../components/core/input/CotroliaSelect";
import { FormHelperText } from "@material-ui/core";

const FieldPartType: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { config, intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl variant="filled" required error={!!props.error}>
      <CotroliaInputLabel id="type-label" error={!!props.error}>
        {t("PartType")}
      </CotroliaInputLabel>
      <CotroliaSelect
        id="type"
        labelId="type-label"
        value={intervention.part.type}
        native
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              type: e.target.value,
            },
          });
          props.onChange && props.onChange();
        }}
      >
        <option value="" />
        {config.partType &&
          Object.keys(config.partType)
            .sort((a, b) =>
              config.partType[a].label.localeCompare(config.partType[b].label),
            )
            .map((key) => (
              <optgroup label={config.partType[key].label} key={key}>
                {config.partType[key].values &&
                  Object.keys(config.partType[key].values)
                    .sort((subkey1, subkey2) =>
                      config.partType[key].values[subkey1].localeCompare(
                        config.partType[key].values[subkey2],
                      ),
                    )
                    .map((subkey) => (
                      <option key={subkey} value={subkey}>
                        {config.partType[key].values[subkey]}
                      </option>
                    ))}
              </optgroup>
            ))}
      </CotroliaSelect>
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldPartType;
