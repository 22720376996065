import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText } from "@material-ui/core";
import CotroliaAutocomplete from "../../../../../components/core/input/CotroliaAutocomplete";
import { PartManufacturer } from "../../../types/NewInterventionFormTypes";
import configurationService from "../../../../../services/configuration.service";

const FieldManufacturer: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaAutocomplete
        id="manufacturer"
        label={t("PartManufacturer")}
        value={intervention.part.manufacturer ?? { id: -1, label: "" }}
        required
        error={!!props.error}
        onChange={(newValue: PartManufacturer) => {
          setIntervention({
            ...intervention,
            part: {
              ...intervention.part,
              manufacturer: newValue,
            },
          });
          props.onChange && props.onChange();
        }}
        getOptions={(searchValue: string) =>
          configurationService
            .getDictionary("c_supercotrolia_manufacturer", "label", searchValue)
            .then((res) => {
              const ret: PartManufacturer[] = [];
              Object.keys(res).forEach((key) => {
                ret.push({ id: key, label: res[key] });
              });
              return ret;
            })
        }
        getOptionLabel={(option: PartManufacturer) => {
          return option?.label ?? "";
        }}
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldManufacturer;
