import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../features/user/context/auth-context";
import FullPageLoader from "../core/FullPageLoader";

let userReloaded = false;

export const RequireNoAuth: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [update, forceUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (!userReloaded && !auth.user) {
      auth.fetchConnectedUser().then(() => forceUpdate(!update));
    }
    userReloaded = true;
  }, [auth, update]);

  return (
    <>
      {!auth.user && userReloaded && <Outlet />}
      {auth.user && (
        <Navigate to={location.state?.from ?? "/"} state={undefined} replace />
      )}
      {!auth.user && !userReloaded && <FullPageLoader />}
    </>
  );
};
