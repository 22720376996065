import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n";
import Corner from "./components/core/Corner";
import {
  transitions,
  positions,
  Provider as AlertProvider,
  AlertCustomOptions,
} from "react-alert";
import AppConstant from "./utils/appConstant";
import { ConfigurationProvider } from "./context/configuration-context";
import MatomoTracker from "./components/MatomoTracker";

// Alert configuration
const alertOptions: AlertCustomOptions = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: AppConstant.cornerZIndex,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={Corner} {...alertOptions}>
      <ConfigurationProvider>
        <MatomoTracker>
          <App />
        </MatomoTracker>
      </ConfigurationProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
