import React, { useEffect } from "react";
import "./App.scss";
import AuthenticatedApp from "./components/AuthenticatedApp";
import { ThemeProvider } from "@material-ui/core";
import { MenuProvider } from "./context/menu-context";
import FullPageLoader from "./components/core/FullPageLoader";
import { useConfiguration } from "./context/configuration-context";
import UnderMaintenance from "./components/core/UnderMaintenance";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { RequireAuth } from "./components/auth/RequireAuth";
import { RequireNoAuth } from "./components/auth/RequireNoAuth";
import LoginOrRegister from "./components/auth/LoginOrRegister";
import ValidateAccount from "./components/auth/ValidateAccount";
import ResetPassword from "./components/pages/ResetPassword";
import InterventionDetail from "./components/intervention/detail/InterventionDetail";
import LatestIntervention from "./components/intervention/detail/LatestIntervention";
import InterventionProposal from "./components/intervention/proposal/InterventionProposal";
import InterventionInvoice from "./components/intervention/invoice/InterventionInvoice";
import InterventionInvoiceDiffered from "./components/intervention/invoice-differed/InterventionInvoiceDiffered";
import InterventionList from "./components/pages/InterventionList";
import NewInterventionPage from "./components/pages/NewInterventionPage";
import Dashboard from "./components/pages/Dashboard";
import Contact from "./components/pages/Contact";
import NewInterventionProvider from "./features/newintervention/context/NewInterventionContext";
import Profile from "./components/profile/Profile";
import UnauthenticatedApp from "./components/UnauthenticatedApp";
import { AuthProvider } from "./features/user/context/auth-context";
import { useSentry } from "./hooks/SentryHook";

const App: React.FC = () => {
  const configuration = useConfiguration();
  const { initSentry } = useSentry();

  useEffect(() => initSentry(), []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthProvider />}>
        <Route element={<RequireNoAuth />}>
          <Route element={<UnauthenticatedApp />}>
            <Route
              path="/login"
              element={<LoginOrRegister variant="login" />}
            />
            <Route
              path="/register"
              element={<LoginOrRegister variant="register" />}
            />
            <Route
              path="/forget-password"
              element={<LoginOrRegister variant="forget-password" />}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/validate-account" element={<ValidateAccount />} />
          </Route>
        </Route>
        <Route element={<RequireAuth />}>
          <Route element={<AuthenticatedApp />}>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/intervention/ongoing"
              element={<InterventionList variant="ongoing" />}
            />
            <Route
              path="/intervention/history"
              element={<InterventionList variant="closed" />}
            />
            <Route
              path="/intervention/new"
              element={
                <NewInterventionProvider>
                  <NewInterventionPage />
                </NewInterventionProvider>
              }
            />
            <Route path="/intervention/:id" element={<InterventionDetail />} />
            <Route
              path="/intervention/latest"
              element={<LatestIntervention />}
            />
            <Route
              path="/intervention/:id/proposal"
              element={<InterventionProposal />}
            />
            <Route
              path="/intervention/:id/invoice"
              element={<InterventionInvoice />}
            />
            <Route
              path="/intervention/:id/invoice-differed"
              element={<InterventionInvoiceDiffered />}
            />
            <Route path="/profile" element={<Profile />} />

            <Route path="/contact" element={<Contact />} />
          </Route>
        </Route>
      </Route>,
    ),
  );

  return (
    <>
      {configuration.loading ? (
        <FullPageLoader />
      ) : configuration.underMaintenance ? (
        <UnderMaintenance title={configuration.name} />
      ) : (
        <ThemeProvider theme={configuration.themes.theme}>
          <MenuProvider>
            <RouterProvider router={router} />
          </MenuProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
