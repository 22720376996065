import React from "react";
import Header from "../core/Header";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useTranslation } from "react-i18next";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import CircleButton from "../core/CircleButton";
import { Link } from "react-router-dom";
import NotificationDashboardList from "../core/NotificationDashboardList";
import Content from "../core/layout/Content";
import InterventionDashboardList from "../core/InterventionDashboardList";
import { useConfiguration } from "../../context/configuration-context";
import CotroliaNewsDashboardList from "../core/CotroliaNewsDashboardList";

const Dashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const configuration = useConfiguration();
  const onSmartphone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Header
        focus={false}
        title={t("Dashboard")}
        titleIcon={<DashboardIcon />}
        titleDesc=""
      >
        {onSmartphone ? (
          <Link to="/intervention/new" style={{ textDecoration: "none" }}>
            <CircleButton color="primary" variant="contained">
              <AddCircleOutline />
            </CircleButton>
          </Link>
        ) : (
          <Link to="/intervention/new" style={{ textDecoration: "none" }}>
            <Button
              color="primary"
              variant="contained"
              endIcon={<AddCircleOutline />}
            >
              {t("NewIntervention")}
            </Button>
          </Link>
        )}
      </Header>
      <Content>
        <NotificationDashboardList />
        <InterventionDashboardList />
        {configuration.sector === "1" && <CotroliaNewsDashboardList />}
      </Content>
    </>
  );
};

export default Dashboard;
