import React from "react";
import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

const Content = ({ centered = false, children }) => {
  const theme = useTheme();
  const classes = makeStyles(() => ({
    container: {
      display: "flex",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      borderRadius: "0",
      backgroundColor: theme.palette.background.default,
      justifyContent: "center",
      padding: theme.spacing(4, 15, 4, 15),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3, 10, 3, 10),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3, 2, 3, 2),
      },
    },
    content: {
      width: "100%",
      maxWidth: centered ? 1024 : undefined,
      zIndex: theme.zIndex.drawer,
      display: "flex",
      flexDirection: "column",
    },
  }))();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default Content;
