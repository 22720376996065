import { Dispatch, SetStateAction, useState } from "react";
import { Theme, createTheme } from "@material-ui/core";

const defaultTheme = createTheme();

export interface CustomThemeType {
  theme: Theme;
  menuTheme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
  setMenuTheme: Dispatch<SetStateAction<Theme>>;
}

const useCustomTheme = (): CustomThemeType => {
  const [theme, setTheme] = useState(defaultTheme);
  const [menuTheme, setMenuTheme] = useState(defaultTheme);

  return {
    theme,
    menuTheme,
    setTheme,
    setMenuTheme,
  };
};

export default useCustomTheme;
