import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../core/Header";
import MemoryIcon from "@material-ui/icons/Memory";
import { useMenu } from "../../context/menu-context";
import CircleButton from "../core/CircleButton";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import { Box, Button, Collapse, makeStyles } from "@material-ui/core";
import HeaderActions from "../core/HeaderActions";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link } from "react-router-dom";
import Content from "../core/layout/Content";
import NoIntervention from "../core/NoIntervention";
import { useAuth } from "../../features/user/context/auth-context";
import interventionService from "../../services/intervention.service";
import displayCards from "../../utils/intervention";
import { useNavigate } from "react-router-dom";
import SortOrFilter from "../core/SortOrFilter";
import { Pagination } from "@material-ui/lab";
import AppConstant from "../../utils/appConstant";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    justifyContent: "center",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
}));

const InterventionList = ({ variant = "ongoing" }) => {
  const { t } = useTranslation();
  const menu = useMenu();
  const classes = useStyle();
  const [interventions, setInterventions] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { user } = useAuth();
  const [interventionNb, setInterventionNb] = useState(
    variant === "ongoing"
      ? user.thirdparty.ongoingInterNb
      : user.thirdparty.closedInterNb,
  );
  const navigate = useNavigate();
  const CARD_LIMIT = 6;
  const [filters, setFilters] = useState(null);
  const [maxpage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    if (variant === "ongoing") {
      interventionService
        .listIntervention(CARD_LIMIT, page, filters, { tms: "DESC" })
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setInterventions(res.data.results);
            setInterventionNb(res.data.total);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else {
      interventionService
        .listHistoricIntervention(CARD_LIMIT, page, filters, { tms: "DESC" })
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setInterventions(res.data.results);
            setInterventionNb(res.data.total);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }, [filters, page, variant]);

  useEffect(() => {
    let max = Math.ceil(interventionNb / CARD_LIMIT);
    if (max < 1) {
      max = 1;
    }
    setMaxPage(max);
    setPage(1);
  }, [interventionNb]);

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    if (user && user.thirdparty) {
      if (variant === "ongoing") {
        setInterventionNb(user.thirdparty.ongoingInterNb);
      } else {
        setInterventionNb(user.thirdparty.closedInterNb);
      }
    }
  }, [user, variant]);

  return (
    <>
      <Header
        focus={false}
        title={t("MyIntervention")}
        titleIcon={<MemoryIcon />}
        titleDesc={variant === "ongoing" ? t("OnGoing") : t("Historic")}
      >
        {menu.onSmartphone ? (
          <HeaderActions>
            <CircleButton
              color="primary"
              onClick={() => {
                setDisplayFilter(!displayFilter);
              }}
            >
              <FilterListIcon />
            </CircleButton>
            <Link to="/intervention/new" style={{ textDecoration: "none" }}>
              <CircleButton color="primary" variant="contained">
                <AddCircleOutline />
              </CircleButton>
            </Link>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              color="primary"
              endIcon={<FilterListIcon />}
              onClick={() => {
                setDisplayFilter(!displayFilter);
              }}
            >
              {t("SortOrFilter")}
            </Button>
            <Link to="/intervention/new" style={{ textDecoration: "none" }}>
              <Button
                style={{ margin: "0px 16px" }}
                color="primary"
                variant="contained"
                endIcon={<AddCircleOutline />}
              >
                {t("NewIntervention")}
              </Button>
            </Link>
          </HeaderActions>
        )}
      </Header>
      <Content>
        {interventionNb === 0 ? (
          <Box className={classes.root}>
            <NoIntervention />
          </Box>
        ) : (
          <>
            <Collapse in={displayFilter}>
              <SortOrFilter applyFilters={applyFilters} variant={variant} />
            </Collapse>
            <Box className={classes.listContainer}>
              {displayCards(
                isLoading,
                interventionNb > CARD_LIMIT ? CARD_LIMIT : interventionNb,
                interventions,
              )}
            </Box>
          </>
        )}
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2em",
          }}
        >
          <Pagination
            count={maxpage}
            page={page}
            onChange={(event, page) => {
              setPage(page);
            }}
            variant="outlined"
            color="primary"
            size="large"
          />
        </Box>
      </Content>
    </>
  );
};

export default InterventionList;
