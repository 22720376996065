// MatomoTracker.js
import React, { useEffect } from "react";
import { initMatomo, trackPageView } from "matomo-react-tracker";
import { useConfiguration } from "../context/configuration-context";

const MatomoTracker = ({ children }) => {
  const configuration = useConfiguration();
  useEffect(() => {
    if (!!configuration.matomoUrl && !!configuration.idMatomoTrack) {
      initMatomo(configuration.matomoUrl, configuration.idMatomoTrack);
      trackPageView(); // Track initial page view
    }
  }, [configuration.idMatomoTrack, configuration.matomoUrl]);

  return <>{children}</>;
};

export default MatomoTracker;
