import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import CotroliaInputLabel from "../../../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../../../components/core/input/CotroliaSelect";
import { MenuItem } from "@material-ui/core";

const FieldFrequency: React.FC = () => {
  const { t } = useTranslation();
  const { config, intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl variant="filled">
      <CotroliaInputLabel id="frequency-label">
        {t("PartFrequency")}
      </CotroliaInputLabel>
      <CotroliaSelect
        id="frequency"
        labelId="frequency-label"
        value={intervention.part.frequency}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setIntervention({
            ...intervention,
            part: { ...intervention.part, frequency: e.target.value },
          });
        }}
      >
        {config?.partFrequency &&
          Object.keys(config.partFrequency).map((key) => (
            <MenuItem value={key} key={key}>
              {config.partFrequency[key]}
            </MenuItem>
          ))}
      </CotroliaSelect>
    </InterFormControl>
  );
};

export default FieldFrequency;
