import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import DividerWithIcon from "./DividerWithAction";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useNavigate } from "react-router";
import AppConstant from "../../utils/appConstant";
import { useAuth } from "../../features/user/context/auth-context";
import interventionService from "../../services/intervention.service";
import displayCards from "../../utils/intervention";
import NoIntervention from "./NoIntervention";

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
}));

const InterventionDashboardList = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [interventionNb, setInterventionNb] = useState(null);
  const [interventions, setInterventions] = useState(null);
  const { user } = useAuth();
  const CARD_LIMIT = 3;

  useEffect(() => {
    interventionService
      .listIntervention(CARD_LIMIT, 1, null, { tms: "DESC" })
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setInterventions(res.data.results);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (user && user.thirdparty) {
      setInterventionNb(user.thirdparty.ongoingInterNb);
    }
  }, [user]);

  const handleAction = () => {
    navigate("/intervention/ongoing");
  };

  return (
    <>
      <DividerWithIcon
        title={t("MyLastIntervention")}
        icon={<ArrowForwardIcon />}
        action={handleAction}
        actionName={t("OnGoing")}
      />

      {interventionNb > 0 ? (
        <Box className={classes.cardContainer}>
          {displayCards(
            isLoading,
            interventionNb > CARD_LIMIT ? CARD_LIMIT : interventionNb,
            interventions,
          )}
        </Box>
      ) : (
        <NoIntervention />
      )}
    </>
  );
};

export default InterventionDashboardList;
