import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText, useTheme } from "@material-ui/core";
import CotroliaAutocomplete from "../../../../../components/core/input/CotroliaAutocomplete";
import { CarModel } from "../../../types/NewInterventionFormTypes";
import _ from "lodash";
import InterFormControl from "../../InterFormControl";

const FieldCarModel: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { config, intervention, setIntervention } = useNewInterventionForm();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaAutocomplete
        id="model-car-label"
        label={t("CarModel")}
        value={intervention.car.carModel ?? { id: -1, label: "" }}
        error={!!props.error}
        onChange={(model?: CarModel) => {
          setIntervention({
            ...intervention,
            car: { ...intervention.car, carModel: model },
          });
          props.onChange && props.onChange();
        }}
        getOptions={(searchValue: string) => {
          const options: { id: string; label: string }[] = [];
          if (intervention.car.carBrand) {
            const models =
              config.carBrandModel[intervention.car.carBrand.id].values;
            Object.keys(models).forEach((key) => {
              options.push({ id: key, label: models[key] });
            });
          }
          const filteredOptions = options.filter((opt) =>
            opt.label.toLowerCase().includes(searchValue.toLowerCase()),
          );
          return new Promise((resolve) =>
            resolve(_.sortBy(filteredOptions, (o) => o.label)),
          );
        }}
        disabled={!intervention.car.carBrand}
        labelColorDisabled={theme.palette.grey[400]}
        required
        getOptionLabel={(option: { id: string; label: string }) => {
          return option?.label ?? "";
        }}
      />
      {props.error && (
        <FormHelperText variant="outlined">{props.error}</FormHelperText>
      )}
    </InterFormControl>
  );
};

export default FieldCarModel;
