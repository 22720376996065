import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  Card,
  Checkbox,
  FormLabel,
  Grid,
  GridSize,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useAuth } from "../../../../user/context/auth-context";
import { ShippingMethod } from "../../../types/NewInterventionFormTypes";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StoreIcon from "@material-ui/icons/Store";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

const FieldMethod: React.FC<{
  customPickingChoice?: Partial<ShippingChoice>;
  customPersonalChoice?: Partial<ShippingChoice>;
  customDeskChoice?: Partial<ShippingChoice>;
}> = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      {user && (
        <Grid container spacing={4}>
          {user.individual !== true && (
            <ShippingChoiceItem
              method={ShippingMethod.Picking}
              md={4}
              icon={props.customPickingChoice?.icon ?? <SwapHorizIcon />}
              title={props.customPickingChoice?.title ?? t("PickingMethod")}
              info1={
                props.customPickingChoice?.info1 ?? t("PickingMethodInfo1")
              }
              info2={
                props.customPickingChoice?.info2 ?? t("PickingMethodInfo2")
              }
            />
          )}
          <ShippingChoiceItem
            method={ShippingMethod.Personal}
            md={user.individual !== true ? 4 : 6}
            icon={props.customPersonalChoice?.icon ?? <LocalShippingIcon />}
            title={props.customPersonalChoice?.title ?? t("PersonalMethod")}
            info1={
              props.customPersonalChoice?.info1 ?? t("PersonalMethodInfo1")
            }
            info2={
              props.customPersonalChoice?.info2 ?? t("PersonalMethodInfo2")
            }
          />
          <ShippingChoiceItem
            method={ShippingMethod.Desk}
            md={user.individual !== true ? 4 : 6}
            icon={props.customDeskChoice?.icon ?? <StoreIcon />}
            title={props.customDeskChoice?.title ?? t("DeskMethod")}
            info1={props.customDeskChoice?.info1 ?? t("DeskMethodInfo1")}
            info2={props.customDeskChoice?.info2 ?? t("DeskMethodInfo2")}
          />
        </Grid>
      )}
    </>
  );
};

export interface ShippingChoice {
  icon: React.ReactNode;
  title: string;
  info1: string;
  info2: string;
}

interface ShippingChoiceProps extends ShippingChoice {
  method: ShippingMethod;
  md?: boolean | GridSize;
}

const ShippingChoiceItem: React.FC<ShippingChoiceProps> = (props) => {
  const theme = useTheme();
  const { intervention, setIntervention } = useNewInterventionForm();
  const checked = intervention.shipping.method === props.method;

  const classes = makeStyles({
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 64,
      width: "100%",
      "& > svg": {
        color: theme.palette.primary.main,
        width: "100%",
        height: "100%",
      },
    },
  })();

  const handleCheck = (): void => {
    setIntervention({
      ...intervention,
      shipping: {
        ...intervention.shipping,
        method: props.method,
      },
    });
  };
  return (
    <Grid item xs={12} md={props.md}>
      <InterFormControl>
        <FormLabel>
          <Card
            style={{
              backgroundColor: theme.palette.grey[50],
              borderRadius: 10,
              height: 288,
              outline: checked
                ? `2px solid ${theme.palette.primary.main}`
                : "none",
              cursor: "pointer",
              padding: theme.spacing(3),
              boxSizing: "border-box",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={(_e, value) => {
                if (value) {
                  handleCheck();
                }
              }}
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                color: theme.palette.primary.main,
              }}
            />
            <Grid
              container
              direction="column"
              spacing={2}
              style={{
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
                flexWrap: "nowrap",
              }}
            >
              <Box className={classes.iconContainer}>{props.icon}</Box>
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                }}
              >
                {props.title}
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "center",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {props.info1}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    color: theme.palette.text.secondary,
                  }}
                >
                  {props.info2}
                </Typography>
              </Box>
            </Grid>
          </Card>
        </FormLabel>
      </InterFormControl>
    </Grid>
  );
};

export default FieldMethod;
