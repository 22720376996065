import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useAuth } from "../../features/user/context/auth-context";
import { Link } from "react-router-dom";
import CotroliaPassword from "../core/input/CotroliaPassword";

const useStyle = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    background: theme.palette.paper,
    width: "30vw",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      alignSelf: "unset",
    },
  },
  title: {
    margin: `${theme.spacing(2)}px 0`,
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  link: {
    textDecoration: "none",
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
}));

const emptyError = {
  value: false,
  msg: "",
};

const LoginForm = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const auth = useAuth();
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(emptyError);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(emptyError);
  const [isLoading, setLoading] = useState(false);

  const handleLogin = async () => {
    let btnActive = true;

    if (email === "") {
      setEmailError({
        value: true,
        msg: t("FieldRequired"),
      });
      btnActive = false;
    }

    if (password === "") {
      setPasswordError({
        value: true,
        msg: t("FieldRequired"),
      });
      btnActive = false;
    }

    if (btnActive && !isLoading) {
      setLoading(true);
      await auth.login(email, password).finally(() => setLoading(false));
    }
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailError(emptyError);
    setEmail(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPasswordError(emptyError);
    setPassword(value);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {t("Connect")}
      </Typography>

      <FormControl className={classes.inputContainer}>
        <TextField
          id="email"
          label={t("YourEmail")}
          variant="filled"
          color="secondary"
          aria-describedby="email-helper"
          onChange={(e) => handleEmailChange(e)}
          value={email}
          error={emailError.value}
          helperText={emailError.value ? emailError.msg : null}
        />
        <FormHelperText id="email-helper">
          {t("YourEmailHelperLogin")}
        </FormHelperText>
      </FormControl>

      {/* #129 */}
      <CotroliaPassword
        className={classes.inputContainer}
        label={t("YourPassword")}
        value={password}
        helper={t("YourPasswordHelper")}
        handler={(password) => {
          setPasswordError(emptyError);
          setPassword(password);
        }}
      />

      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={
          isLoading ? (
            <CircularProgress color={theme.palette.text.primary} size={24} />
          ) : (
            <ArrowForwardIcon />
          )
        }
        onClick={handleLogin}
        disabled={passwordError.value === true || emailError.value === true}
      >
        {!isLoading && t("ConnectMyself")}
        {isLoading && t("Connexion en cours")}
      </Button>

      <Link to={`/forget-password?email=${email}`} className={classes.link}>
        <Button
          color="secondary"
          className={classes.btn}
          endIcon={<ArrowForwardIcon />}
        >
          {t("ForgottenPassword")}
        </Button>
      </Link>
    </Box>
  );
};

export default LoginForm;
