import React from "react";
import InterFormControl from "../../InterFormControl";
import { useTranslation } from "react-i18next";
import CotroliaVatInput from "../../../../../components/core/input/CotroliaVatInput";
import { useAuth } from "../../../../user/context/auth-context";

const FieldVatNumber: React.FC<{
  error?: string;
  onChange?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <InterFormControl error={!!props.error}>
      <CotroliaVatInput
        label={t("VatNumber")}
        value={user?.thirdparty.vat}
        disabled
        onValidate={() => {}}
        onChange={() => {}}
      />
    </InterFormControl>
  );
};

export default FieldVatNumber;
