import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import CompanyLogo from "../core/CompanyLogo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useConfiguration } from "../../context/configuration-context";

const useStyle = makeStyles((theme) => ({
  container: {
    alignSelf: "stretch",
    width: "30vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      alignItems: "center",
      textAlign: "center",
      padding: theme.spacing(2),
    },
  },
  desc: {
    margin: `${theme.spacing(6)}px 0`,
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "initial",
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  cardContent: {
    margin: `${theme.spacing(1)}px 0`,
  },
  cardActions: {
    margin: `${theme.spacing(1)}px 0`,
    "& > a": {
      textDecoration: "none",
    },
  },
  btn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const LoginRegisterLeftContainer = ({
  cardTitle,
  cardBtnLabel,
  cardRedirect,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const declination =
    configuration.declination.indexOf("dcontent") !== -1 ? "light" : "dark";

  const isMobile = window.innerWidth <= 425;

  return (
    <Box className={classes.container}>
      {!isMobile && <CompanyLogo declination={declination} />}
      <Typography variant="h5" className={classes.desc}>
        {t("MainSentence")}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h4" className={classes.title}>
            {cardTitle}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Link to={cardRedirect}>
            <Button variant="contained" className={classes.btn}>
              {cardBtnLabel}
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default LoginRegisterLeftContainer;
