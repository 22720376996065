import React from "react";

import { Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CotroliaStepper from "../../core/stepper/CotroliaStepper";
import CotroliaStepperActions from "../../core/stepper/CotroliaStepperActions";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppConstant from "../../../utils/appConstant";
import HTMLContainer from "../../core/HTMLContainer";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
}));

const StepConfirm = ({
  choice,
  data,
  steps,
  activeStep,
  prevStep,
  validate,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const displayContent = () => {
    if (choice === AppConstant.invoiceChoiceBankTransfer) {
      return <HTMLContainer>{data.bankTransferInstructions}</HTMLContainer>;
    } else {
      return <HTMLContainer>{data.chequeInstructions}</HTMLContainer>;
    }
  };

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("InvoiceConfirm")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("InvoiceConfirmDesc")}
      </Typography>
      <CotroliaStepper
        steps={steps}
        activeStep={activeStep}
        variant="secondary"
      />

      {displayContent()}

      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          color="secondary"
          onClick={prevStep}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("Previous")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ArrowForwardIcon />}
          onClick={validate}
          disabled={choice === ""}
        >
          {t("Validate")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default StepConfirm;
