import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useMenu } from "../../../context/menu-context";

import interventionService from "../../../services/intervention.service";
import Content from "../../core/layout/Content";
import Header from "../../core/Header";
import HeaderActions from "../../core/HeaderActions";
import MemoryIcon from "@material-ui/icons/Memory";
import ContentCard from "../../core/layout/ContentCard";
import NewInterventionStepSkeleton from "../../core/skeleton/NewInterventionStepSkeleton";
import moment from "moment";
import AppConstant from "../../../utils/appConstant";
import { useNavigate } from "react-router-dom";
import ContentTwoSided, {
  LeftSide,
  RightSide,
} from "../../core/ContentTwoSided";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import CotroliaTextField from "../../core/input/CotroliaTextField";
import TableTotal from "../../core/TableTotal";

const useStyle = makeStyles((theme) => ({
  titleRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    overflowX: "auto",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(
      0,
    )}px ${theme.spacing(1)}px`,
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableRow-root:not(.MuiTableRow-head):last-of-type .MuiTableCell-root":
      {
        borderBottom: "none",
      },
  },
}));

const InterventionInvoiceDiffered = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const alert = useAlert();
  const menu = useMenu();
  const classes = useStyle();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get invoice datas
    interventionService
      .getInterventionInvoice(id)
      .then((res) => {
        // Verify if we are in the right step
        if (
          res.interStatus &&
          parseInt(res.interStatus) !== AppConstant.interStatusRepair
        ) {
          alert.show(t("InterInvoiceNotAvailableDesc"), {
            title: t("InterInvoiceNotAvailable"),
            close: () => {
              alert.remove(alert);
            },
            type: "error",
          });
          // Redirect to intervention view
          navigate("/intervention/" + id, { replace: true });
        }

        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert.show(t("InterNotFoundDesc"), {
          title: t("InterNotFound"),
          close: () => {
            alert.remove(alert);
          },
          type: "error",
        });
      });
  }, [id, alert, t, navigate]);

  return (
    <>
      <Header focus={true} variant="secondary">
        <HeaderActions>
          <Box display="flex" alignItems="center">
            <Box className={classes.titleRightContainer}>
              <Typography variant="h4">
                {data ? (
                  data.interRef + " " + t("Invoice")
                ) : (
                  <Skeleton width={200} />
                )}
              </Typography>
              <Typography variant="subtitle1">
                {data ? (
                  t("YourRef") +
                  " : " +
                  (data.interCustomerRef
                    ? data.interCustomerRef
                    : t("NoCustomerRef"))
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
              <Typography variant="subtitle1">
                {data ? (
                  t("InterventionInvoiceDifferedDate") +
                  " : " +
                  (data.invoiceDate
                    ? moment.unix(data.invoiceDate).format("ll")
                    : t("NoDate"))
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
            </Box>
            {!menu.onSmartphone && <MemoryIcon />}
          </Box>
        </HeaderActions>
      </Header>
      <Content>
        <ContentCard>
          {loading ? (
            <NewInterventionStepSkeleton />
          ) : (
            <>
              <Typography variant="h4" className={classes.title} align="center">
                {t("Overview")}
              </Typography>
              <ContentTwoSided>
                <LeftSide>
                  <CotroliaFormControl>
                    <CotroliaTextField
                      label={t("PartType")}
                      value={data.partType}
                      disabled
                    />
                  </CotroliaFormControl>
                  <CotroliaFormControl>
                    <CotroliaTextField
                      label={t("PartManufacturer")}
                      value={data.manufacturer}
                      disabled
                    />
                  </CotroliaFormControl>
                  <CotroliaFormControl>
                    <CotroliaTextField
                      label={t("PartFrequency")}
                      value={data.failureFrequency}
                      disabled
                    />
                  </CotroliaFormControl>
                </LeftSide>
                <RightSide>
                  <CotroliaFormControl>
                    <CotroliaTextField
                      label={t("PartDescription")}
                      value={data.interDesc}
                      disabled
                      multiline
                      rows={4}
                    />
                  </CotroliaFormControl>
                </RightSide>
              </ContentTwoSided>

              <Box m={2}>
                <Typography variant="h6" align="center">
                  {t("Details")}
                </Typography>
              </Box>

              {/* Lines table */}
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("ProductCode")}</TableCell>
                      <TableCell>{t("ProductName")}</TableCell>
                      <TableCell>{t("TotalHT")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.lines.map((line, idx) => {
                      return (
                        <TableRow key={idx}>
                          {line.type === "freeline" ? (
                            <TableCell colSpan={3}>
                              <div
                                dangerouslySetInnerHTML={{ __html: line.title }}
                              ></div>
                            </TableCell>
                          ) : (
                            <>
                              <TableCell>{line.code}</TableCell>
                              <TableCell>{line.title}</TableCell>
                              <TableCell>{line.total_ht}</TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box m={2} width="100%">
                <TableTotal
                  totalHt={data.total_ht || 0}
                  totalTva={data.total_tva || 0}
                  totalTtc={data.total_ttc || 0}
                />
              </Box>

              <Box m={2}>
                <Typography variant="h6" align="center">
                  {t("YourChoice")}
                </Typography>
              </Box>

              <CotroliaFormControl>
                <CotroliaTextField
                  label={t("PayementConditions")}
                  value={data.paymentConditions}
                  disabled
                />
              </CotroliaFormControl>

              <CotroliaFormControl>
                <CotroliaTextField
                  label={t("PayementChoice")}
                  value={data.paymentMethodSuggested}
                  disabled
                />
              </CotroliaFormControl>
            </>
          )}
        </ContentCard>
      </Content>
    </>
  );
};

export default InterventionInvoiceDiffered;
