import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  divider: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  dividerLine: {
    width: "1px",
    height: "100%",
    backgroundColor: theme.palette.divider,
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "1px",
    },
  },
  dividerText: {
    fontWeight: "bold",
    color: theme.palette.text.disabled,
    textTransform: "uppercase",
  },
}));

const LoginRegisterDivider = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Box className={classes.divider}>
      <Box className={classes.dividerLine}></Box>
      <Box component="span" m={2} className={classes.dividerText}>
        {t("Or")}
      </Box>
      <Box className={classes.dividerLine}></Box>
    </Box>
  );
};

export default LoginRegisterDivider;
