import { Button, Box, useTheme, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import ContentTwoSided, { LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { emptyAddress } from "../../utils/model";
import { capitalize, validPhoneNumber } from "../../utils/validation";
import authService from "../../services/auth.service";
import { useAuth } from "../../features/user/context/auth-context";
import CotroliaAddress from "../core/input/CotroliaAddress";
import { Address } from "../../features/newintervention/types/NewInterventionFormTypes";
import { User } from "../../features/user/types/userTypes";
import CotroliaVatInput from "../core/input/CotroliaVatInput";

interface ProfileErrors {
  nameError?: string;
  vatError?: string;
  phoneError?: string;
  addressError?: string;
}

const ProfileCompany: React.FC<{
  onUpdate: (user: User) => void;
}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user, setUser } = useAuth();
  const alert = useAlert();
  const [vatValid, setVatValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<ProfileErrors>({});

  const classes = makeStyles(() => ({
    actionContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      "& > *": {
        marginLeft: theme.spacing(1),
      },
      marginTop: theme.spacing(3),
    },
  }))();

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(
    user?.thirdparty.name ? user.thirdparty.name : "",
  );
  const [vat, setVat] = useState(
    user?.thirdparty.vat ? user.thirdparty.vat : "",
  );
  const [phone, setPhone] = useState(
    user?.thirdparty.phone ? user.thirdparty.phone : "",
  );
  const [address, setAddress] = useState(
    user?.thirdparty.address ?? emptyAddress,
  );

  const validateProfile = (): boolean => {
    const profileErrors: ProfileErrors = {
      nameError: !name ? t("FieldRequired") : undefined,
      vatError:
        !vat || (vat !== user?.thirdparty.vat && !vatValid)
          ? t("VatRequired")
          : undefined,
      phoneError:
        !phone || !validPhoneNumber(phone) ? t("FieldRequired") : undefined,
      addressError: validateAddress(address),
    };
    setErrors(profileErrors);
    return (
      !profileErrors.nameError &&
      !profileErrors.vatError &&
      !profileErrors.phoneError &&
      !profileErrors.addressError
    );
  };

  const handleCancel = () => {
    setName(user?.thirdparty.name ?? "");
    setVat(user?.thirdparty.vat ?? "");
    setPhone(user?.thirdparty.phone ?? "");
    setAddress(user?.thirdparty.address ?? emptyAddress);
    setEditMode(false);
    setErrors({});
  };

  const validateAddress = (address?: Address) => {
    const isValid =
      address &&
      !!address.street &&
      !!address.city &&
      !!address.zipCode &&
      !!address.countryCode;

    return isValid ? undefined : t("FieldRequired");
  };

  const handleSave = () => {
    if (validateProfile()) {
      authService
        .updateCompany(name, phone, address, vat)
        .then(() => {
          alert.success("", {
            title: t("InformationModified"),
          } as AlertCustomOptions);
          setEditMode(false);

          // Update local user informations
          const userCopy = { ...user! };
          userCopy.thirdparty.name = name;
          userCopy.thirdparty.vat = vat;
          userCopy.thirdparty.address = address;
          userCopy.thirdparty.phone = phone;
          setUser(userCopy);
          props.onUpdate && props.onUpdate(userCopy);
        })
        .catch((err) => {
          console.error(err);
          handleCancel();
          alert.error(err.message, {
            title: t("InternalError"),
          } as AlertCustomOptions);
        });
    }
  };

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("CompanyName")}
              value={name}
              disabled={!editMode}
              required={editMode}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                setName(capitalize(e.target.value ?? ""));
              }}
              error={!!errors.nameError}
            />
          </CotroliaFormControl>
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaTextField
              label={t("Phone")}
              value={phone}
              disabled={!editMode}
              required={editMode}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                setPhone(e.target.value);
              }}
              error={!!errors.phoneError}
            />
            <FormHelperText variant="outlined">
              {t("PhoneHelper")}
            </FormHelperText>
          </CotroliaFormControl>
        </LeftSide>
        <RightSide>
          <CotroliaFormControl style={{ margin: 0 }}>
            <CotroliaVatInput
              label={t("VatNumber")}
              defaultValue={vat}
              disabled={!editMode}
              required={editMode}
              onValidate={(isVatValid) => {
                setVatValid(isVatValid);
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVatValid(false);
                setVat(e.target.value);
              }}
            />
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>
      <Box style={{ marginTop: theme.spacing(3) }}>
        <CotroliaAddress
          label={t("Address")}
          onChange={(value: Address) => {
            setAddress(value);
          }}
          defaultAddress={address}
          disabled={!editMode}
          required={editMode}
          error={!!errors.addressError}
          errorMsg={errors.addressError}
        />
      </Box>
      <Box className={classes.actionContainer}>
        {editMode ? (
          <>
            <Button color="secondary" onClick={handleCancel}>
              {t("Cancel")}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {t("Save")}
            </Button>
          </>
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            {t("Modify")}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ProfileCompany;
