import AppConstant from "../../../utils/appConstant";

export interface NewIntervention {
  id?: string;
  car: Car;
  part: Part;
  shipping: Shipping;
  packaging: Packaging;
  finish: Finish;
}

export interface Car {
  frame?: string;
  carBrand?: CarBrand;
  carModel?: CarModel;
  distance?: string;
  security_code?: string;
  registration?: string;
  customer_effect?: string;
  diagnostic?: string;
  parts_replaced?: string;
}

export interface CarBrand {
  id: string;
  label: string;
}

export interface CarModel {
  id: string;
  label: string;
}

export enum WarrantyValue {
  Yes = AppConstant.warrantyYes,
  No = AppConstant.warrantyNo,
}

export interface Part {
  waranty: WarrantyValue;
  warrantyPrevInter?: PartWarrantyPrevInter;
  type?: string;
  manufacturer?: PartManufacturer;
  description?: string;
  ref?: PartRef;
  refNotVisible: boolean;
  clientRef?: string;
  frequency?: string;
  oldpictures: File[];
  pictures: File[];
  diagFiles: File[];
}

export interface PartWarrantyPrevInter {
  id: string;
  label: string;
}

export interface PartManufacturer {
  id: string;
  label: string;
}

export interface PartRef {
  id: string;
  ref: string;
}

export enum ShippingMethod {
  Picking = AppConstant.shippingMethodPicking,
  Personal = AppConstant.shippingMethodPersonal,
  Desk = AppConstant.shippingMethodDesk,
}

export interface Shipping {
  method: ShippingMethod;
  pickupDate: moment.Moment;
  company?: string;
  informations?: string;
  address?: Address;
  phone?: string;
  closingDates: {
    monday: boolean;
    saturday: boolean;
  };
}

export interface Packaging {
  partNb: number;
  instructions: boolean;
  address?: string;
  complementaryPart: boolean;
  complementaryPartInfos?: string;
}

export interface Finish {
  mainContact?: Contact;
  billingContact?: Contact;
  technicalContact?: Contact;
}

export interface Contact {
  id?: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  type?: ContactType;
}

export enum ContactType {
  Main = "SCMAIN",
  Tech = "SCTECH",
  Billing = "BILLING",
}

export interface Address {
  street: string;
  zipCode: string;
  city: string;
  country: string;
  countryCode: string;
  fullAddress: string;
}

export interface SavedIntervention {
  id: string | null;
  status: SavedInterventionStatus;
  partNbr: string;
  waranty: "1" | "2";
  warrantyPrevInter:
    | PartWarrantyPrevInter
    | {
        id: null;
        label: "";
      };
  description: string;
  clientRef?: string | null;
  frequency?: string | null;
  refNotVisible: boolean;
  pictures: { content: string; name: string }[];
  part: {
    type?: string | null;
    manufacturer:
      | PartManufacturer
      | {
          id: null;
          label: "";
        }
      | null;
    ref: PartRef | { id: null; ref: "" } | "";
  };
  car: {
    frame?: string;
    carBrand: CarBrand | { id: null; ref: "" } | null;
    carModel: CarModel | { id: null; ref: "" } | null;
    distance?: string;
    registration?: string;
    security_code?: string;
    customer_effect?: string;
    diagnostic?: string;
    parts_replaced?: string;
  };
  shipping: {
    method: string;
    pickupDate: string;
    company?: string;
    informations?: string;
    address?: string;
    addressNumber?: string;
    zip?: string;
    city?: string;
    countryCode?: string;
    phone?: string;
    fullAddress?: string;
    closingDates: {
      monday: boolean;
      saturday: boolean;
    };
  };
  mainContact?: Contact;
  billingContact?: Contact;
  technicalContact?: Contact;
}

export interface InterventionToSave {
  id?: string;
  status: SavedInterventionStatus;
  partNbr: number;
  waranty: WarrantyValue | "1" | "0";
  warrantyPrevInter:
    | PartWarrantyPrevInter
    | {
        id: null;
        label: "";
      }
    | null;
  description: string;
  clientRef?: string | null;
  frequency?: string | null;
  refNotVisible: boolean;
  pictures: File[];
  part: {
    type?: string | null;
    manufacturer:
      | PartManufacturer
      | {
          id: null;
          label: "";
        }
      | null;
    ref: PartRef | { id: null; ref: "" } | "";
  };
  car: {
    frame?: string;
    carBrand: CarBrand | { id: null; ref: "" } | null;
    carModel: CarModel | { id: null; ref: "" } | null;
    distance?: string;
    registration?: string;
    security_code?: string;
  };
  customer_effect?: string;
  diagnostic?: string;
  parts_replaced?: string;
  shipping: {
    method: ShippingMethod | "";
    pickupDate?: string;
    company?: string;
    informations?: string;
    address?: string;
    addressNumber?: string;
    zip?: string;
    city?: string;
    countryCode?: string;
    phone?: string;
    closingDates: {
      monday: boolean;
      saturday: boolean;
    };
  };
  mainContact?: Contact;
  billingContact?: Contact;
  technicalContact?: Contact;
  privateNote?: string;
  packing_instructions?: string;
}

export enum SavedInterventionStatus {
  Registered = AppConstant.interStatusRegistered,
  Transmited = AppConstant.interStatusTransmited,
  ToSend = AppConstant.interStatusToSend,
  Sended = AppConstant.interStatusSended,
  Declined = AppConstant.interStatusDeclined,
  Receive = AppConstant.interStatusReceive,
  QuoteReceive = AppConstant.interStatusQuoteReceive,
  QuoteAccepted = AppConstant.interStatusQuoteAccepted,
  PaymentPending = AppConstant.interStatusPaymentPending,
  Repair = AppConstant.interStatusRepair,
  OnTheWay = AppConstant.interStatusOnTheWay,
  Finalized = AppConstant.interStatusFinalized,
  Alert = AppConstant.interStatusAlert,
}
