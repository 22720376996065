import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StarIcon from "@material-ui/icons/Star";
import GroupIcon from "@material-ui/icons/Group";
import PaymentIcon from "@material-ui/icons/Payment";
import ToggleSection from "../../../../components/core/ToggleSection";
import { useAuth } from "../../../user/context/auth-context";
import FieldMainContact from "../fields/finish/FieldMainContact";
import FieldBillingContact from "../fields/finish/FieldBillingContact";
import FieldExternalContact from "../fields/finish/FieldExternalContact";
import {
  ContentTwoSided,
  LeftSide,
  RightSide,
} from "../../../../components/core/ContentTwoSided";
import FieldPickupDate from "../fields/shipping/FieldPickupDate";
import FieldEntityAddress from "../fields/common/FieldEntityAddress";
import { useNewInterventionForm } from "../../context/NewInterventionContext";
import AppConstant from "../../../../utils/appConstant";
import { useStepValidator } from "../../hooks/useStepValidator";
import MemoryIcon from "@material-ui/icons/Memory";
import StoreIcon from "@material-ui/icons/Store";
import FieldClientRef from "../fields/part/FieldClientRef";
import FieldPhone from "../fields/finish/FieldPhone";
import FieldAddress from "../fields/finish/FieldAddress";
import { Address, ShippingMethod } from "../../types/NewInterventionFormTypes";
import { validPhoneNumber } from "../../../../utils/validation";
import FieldCompanyName from "../fields/finish/FieldCompanyName";
import FieldClosingDays from "../fields/finish/FieldClosingDays";
import FieldVatNumber from "../fields/finish/FieldVatNumber";
import FieldInfosDeliveryMan from "../fields/finish/FieldInfosDeliveryMan";

interface StepShippingErrors {
  dateError?: string;
  dateInputError?: string;
  companyError?: string;
  phoneError?: string;
  addressError?: string;
}

const StepFinish: React.FC = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<StepShippingErrors>({});
  const { user } = useAuth();
  const { intervention } = useNewInterventionForm();

  const validateAddress = (address?: Address) => {
    const isValid =
      address &&
      !!address.street &&
      !!address.city &&
      !!address.zipCode &&
      !!address.countryCode;

    return isValid;
  };

  useStepValidator(() => {
    const stepErrors: StepShippingErrors = {
      ...errors,
      dateError:
        intervention.shipping.method === AppConstant.shippingMethodPicking &&
        !intervention.shipping.pickupDate
          ? t("RequiredFieldDateError")
          : undefined,
      companyError: !intervention.shipping.company
        ? t("RequiredFieldCompanyError")
        : undefined,
      phoneError:
        !intervention.shipping.phone ||
        !validPhoneNumber(intervention.shipping.phone)
          ? t("RequiredFieldPhoneError")
          : undefined,
      // Valid address = known address with fulladdress + city + zipcode OR unkown address with street + city + zipCode + countryCode
      addressError: !validateAddress(intervention.shipping.address)
        ? t("RequiredFieldAddressError")
        : undefined,
    };
    setErrors(stepErrors);
    return (
      !stepErrors.dateError &&
      !stepErrors.dateInputError &&
      (!stepErrors.companyError || !!user?.individual) &&
      !stepErrors.phoneError &&
      !stepErrors.addressError
    );
  });

  return (
    <div>
      <ToggleSection
        title={t("YourDemand")}
        icon={<MemoryIcon />}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldPickupDate
              error={errors.dateError || errors.dateInputError}
              onChange={() => {
                setErrors({
                  ...errors,
                  dateError: undefined,
                  dateInputError: undefined,
                });
              }}
              onInputError={(message) => {
                setErrors({ ...errors, dateInputError: message });
              }}
            />
            <FieldEntityAddress />
          </LeftSide>
          <RightSide>
            <FieldClientRef />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>
      {!user?.individual && (
        <ToggleSection
          title={t("YourGarage")}
          description={t("YourGarageDesc")}
          icon={<StoreIcon />}
          defaultExpanded={true}
        >
          <ContentTwoSided>
            <LeftSide>
              <FieldCompanyName
                error={errors.companyError}
                onChange={() =>
                  setErrors({
                    ...errors,
                    companyError: undefined,
                  })
                }
              />
              <FieldPhone
                error={errors.phoneError}
                onChange={() =>
                  setErrors({
                    ...errors,
                    phoneError: undefined,
                  })
                }
              />
              <FieldAddress
                error={errors.addressError}
                onChange={() =>
                  setErrors({
                    ...errors,
                    addressError: undefined,
                  })
                }
              />
            </LeftSide>
            <RightSide>
              <FieldVatNumber />
              <FieldClosingDays />
              {intervention.shipping.method !== ShippingMethod.Desk && (
                <FieldInfosDeliveryMan />
              )}
            </RightSide>
          </ContentTwoSided>
        </ToggleSection>
      )}

      {user?.individual && (
        <ToggleSection
          title={t("YourInformations")}
          description={t("YourInformationsDesc")}
          icon={<StoreIcon />}
          defaultExpanded={true}
        >
          <ContentTwoSided>
            <LeftSide>
              <FieldPhone
                error={errors.phoneError}
                onChange={() => {
                  setErrors({
                    ...errors,
                    phoneError: undefined,
                  });
                }}
              />
            </LeftSide>
            <RightSide>
              <FieldAddress
                error={errors.addressError}
                onChange={() => {
                  setErrors({
                    ...errors,
                    addressError: undefined,
                  });
                }}
              />
            </RightSide>
          </ContentTwoSided>
        </ToggleSection>
      )}

      <ToggleSection
        title={t("MainContact")}
        description={t("MainContactDesc")}
        icon={<StarIcon />}
      >
        <FieldMainContact />
      </ToggleSection>

      {!user?.individual && (
        <>
          <ToggleSection
            title={t("BillingContact")}
            description={t("BillingContactDesc")}
            icon={<PaymentIcon />}
          >
            <FieldBillingContact />
          </ToggleSection>

          <ToggleSection
            title={t("TechnicalContact")}
            description={t("TechnicalContactDesc")}
            icon={<GroupIcon />}
          >
            <FieldExternalContact />
          </ToggleSection>
        </>
      )}
    </div>
  );
};

export default StepFinish;
