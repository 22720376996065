import React, { useState, Children, isValidElement, cloneElement } from "react";
import {
  Box,
  makeStyles,
  Backdrop,
  Typography,
  useTheme,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { useMenu } from "../../context/menu-context";

// Style css
const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(33,33,33,0.9)",
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(24, 26, 1, 26),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2, 1, 2),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  container: {
    overflow: "scroll",
    height: "100%",
  },

  card: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "white",
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    color: theme.palette.grey[900],
  },

  content: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },

  closeButton: {
    color: theme.palette.grey[50],
    float: "right",
  },
}));

// Component Popup backdrop
const Popup = ({ children }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { handleClose });
    }
    return child;
  });

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Box style={{ width: "100%" }}>
        <Button
          className={classes.closeButton}
          endIcon={<CloseIcon />}
          onClick={handleClose}
        >
          <Typography variant="button">{t("Close")}</Typography>
        </Button>
      </Box>
      <Box className={classes.container}>{childrenWithProps}</Box>
    </Backdrop>
  );
};

/**
 * Component Popup Card content
 *
 * @param {title}       string    Popup title
 * @param {description} string    Popup description
 * @param {children}    mix       Popup content
 * @param {action}      function  Popup action function
 * @param {actionLabel} string    Action button label
 * @param {actionIcon}  icon      MUI icon component
 * @returns
 */
const PopupCard = ({
  title,
  description,
  children,
  action,
  actionLabel,
  actionIcon,
  handleClose,
  color = "primary",
  variant = "contained",
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const menu = useMenu();

  const actionFunc = () => {
    handleClose();
    action();
  };

  return (
    <Box className={classes.card}>
      <Typography
        variant={menu.onSmartphone ? "h4" : "h5"}
        style={{ color: theme.palette.text.primary }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ color: theme.palette.text.secondary }}
      >
        {description}
      </Typography>
      {children && <Box className={classes.content}>{children}</Box>}
      {action && (
        <Box
          className={classes.content}
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Button
            size="large"
            variant={variant}
            color={color}
            endIcon={actionIcon}
            onClick={actionFunc}
          >
            {actionLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export { Popup, PopupCard };
