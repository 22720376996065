import React, { useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

const MenuContext = React.createContext();

const MenuProvider = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();
  const onSmartphone = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const closeNavOnSmartphone = () => {
    if (onSmartphone) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (onSmartphone) {
      setIsOpen(false);
    }
  }, [onSmartphone]);

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        onSmartphone,
        toggleOpen,
        closeNavOnSmartphone,
      }}
      {...props}
    />
  );
};

const useMenu = () => React.useContext(MenuContext);

export { MenuProvider, useMenu };
