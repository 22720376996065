import { useTheme } from "@material-ui/core";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./core/Footer";

const UnauthenticatedApp = () => {
  const theme = useTheme();

  return (
    <div
      className="App"
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Outlet />
      <Footer />
    </div>
  );
};

export default UnauthenticatedApp;
