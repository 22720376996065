import API from "./api";

class ConfigurationService {
  constructor() {
    const api = new API();
    this.api = api;
  }

  successCallback = (status, data) => {
    return data;
  };

  /**
   * Method to get the theme configuration set for entity
   */
  getThemeConfiguration = async (langCode) => {
    const data = await this.api
      .get(
        "/supercotrolia/configuration?langCode=" + langCode,
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Method to get the inter configuration
   */
  getInterConfiguration = async () => {
    const data = await this.api
      .get("/supercotrolia/interventions/configuration", this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Method to get the dictionary values
   *
   * @returns List of dico values
   */
  getDictionary = async (table, searchField, searchValue) => {
    const url =
      "/supercotrolia/dictionary?table=" +
      table +
      "&searchField=" +
      searchField +
      "&searchValue=" +
      searchValue;
    const data = await this.api.get(url, this.successCallback).catch((err) => {
      throw err;
    });
    return data;
  };

  /**
   * Method to get the nested dictionary values
   *
   * @returns List of dico values
   */
  getNestedDictionary = async (table, searchField, searchValue) => {
    const url =
      "/supercotrolia/nestdictionary?table=" +
      table +
      "&searchField=" +
      searchField +
      "&searchValue=" +
      searchValue;
    const data = await this.api.get(url, this.successCallback).catch((err) => {
      throw err;
    });
    return data;
  };

  /**
   * Method to get datas from table
   *
   * @returns List of values
   */
  getComplementaryPart = async (table, searchValue) => {
    const url =
      "/supercotrolia/complementary-part?table=" +
      table +
      "&searchValue=" +
      searchValue;
    const data = await this.api.get(url, this.successCallback).catch((err) => {
      throw err;
    });
    return data;
  };

  getPackingInstructions = async (partType, carBrand, carModel, lang) => {
    let url = `/supercotrolia/packinginstructions?type=${partType}&lang=${lang}`;
    if (!!carBrand && !!carModel) {
      url += `&carBrand=${carBrand}&carModel=${carModel}`;
    }
    const data = await this.api.get(url, this.successCallback).catch((err) => {
      throw err;
    });
    return data;
  };

  /**
   * Method to get the brand / model for saved intervention
   *
   * @returns List of dico values
   */
  getBrandModelSaved = async (id) => {
    const url = "/supercotrolia/saved-query-brand-model?id=" + id;
    const data = await this.api.get(url, this.successCallback).catch((err) => {
      throw err;
    });
    return data;
  };
}
export default new ConfigurationService();
