import React, { useState } from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { Contact } from "../../../types/NewInterventionFormTypes";
import SelectOrAddContact from "../../../../../components/core/SelectOrAddContact";
import { Divider, makeStyles } from "@material-ui/core";
import AddContactForm from "../../../../../components/core/AddContactForm";
import AddContactEntry from "../../../../../components/core/AddContactEntry";

const useStyle = makeStyles((theme) => ({
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const FieldBillingContact: React.FC = () => {
  const classes = useStyle();
  const { intervention, setIntervention } = useNewInterventionForm();

  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [displayAddForm, setDisplayAddForm] = useState<boolean>(false);
  const [newContact, setNewContact] = useState<Contact>();

  const handleSelectContact = (contactId: string) => {
    setSelectedContactId(contactId);
    setDisplayAddForm(false);
    setNewContact(undefined);
    setIntervention({
      ...intervention,
      finish: {
        ...intervention.finish,
        billingContact: {
          id: contactId,
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        },
      },
    });
  };

  const handleAddContact = (contact: Contact) => {
    setSelectedContactId(undefined);
    setDisplayAddForm(false);
    setNewContact(contact);
    setIntervention({
      ...intervention,
      finish: { ...intervention.finish, billingContact: contact },
    });
  };

  const handleDeleteNewContact = () => {
    setSelectedContactId(undefined);
    setDisplayAddForm(false);
    setNewContact(undefined);
    setIntervention({
      ...intervention,
      finish: { ...intervention.finish, billingContact: undefined },
    });
  };

  return (
    <>
      {!newContact && (
        <SelectOrAddContact
          selected={selectedContactId}
          onSelect={handleSelectContact}
          onClick={() => setDisplayAddForm(!displayAddForm)}
        />
      )}
      {displayAddForm && (
        <>
          <Divider className={classes.divider} />
          <AddContactForm
            lastname={newContact && newContact.lastname}
            firstname={newContact && newContact.firstname}
            email={newContact && newContact.email}
            phone={newContact && newContact.phone}
            handleAdd={(
              lastname: string,
              firstname: string,
              email: string,
              phone: string,
            ) =>
              handleAddContact({
                lastname: lastname,
                firstname: firstname,
                email: email,
                phone: phone,
              })
            }
            handleCancel={() => setDisplayAddForm(false)}
          />
        </>
      )}
      {!displayAddForm && newContact && (
        <>
          <Divider className={classes.divider} />
          <AddContactEntry
            contact={newContact}
            handleDelete={handleDeleteNewContact}
            handleEdit={() => setDisplayAddForm(true)}
          />
        </>
      )}
    </>
  );
};

export default FieldBillingContact;
