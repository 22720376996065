import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../core/Header";
import { useMenu } from "../../context/menu-context";
import { Button, makeStyles } from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LinkIcon from "@material-ui/icons/Link";
import CircleButton from "../core/CircleButton";
import Content from "../core/layout/Content";
import { useAuth } from "../../features/user/context/auth-context";
import ToggleSection from "../core/ToggleSection";
import StarIcon from "@material-ui/icons/Star";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ProfileMainContact from "./ProfileMainContact";
import ProfileCompany from "./ProfileCompany";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AppConstant from "../../utils/appConstant";
import ProfileBillingContact from "./ProfileBillingContact";
import { Skeleton } from "@material-ui/lab";
import PeopleIcon from "@material-ui/icons/People";
import ProfileTechnicalContact from "./ProfileTechnicalContact";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import LanguageSelector from "../core/LanguageSelector";
import TranslateIcon from "@material-ui/icons/Translate";
import { useConfiguration } from "../../context/configuration-context";

const useStyle = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
}));

const Profile = () => {
  const { t } = useTranslation();
  const menu = useMenu();
  const classes = useStyle();
  const { user, logout } = useAuth();
  const { langs } = useConfiguration();

  const [loading, setLoading] = useState(true);
  const [billingContacts, setBillingContacts] = useState([]);
  const [techContacts, setTechContacts] = useState([]);

  const handleLogoutClick = () => {
    logout();
  };

  useEffect(() => {
    setLoading(true);
    if (user.thirdparty && user.thirdparty.contactList.length > 0) {
      // Filter billing contacts
      setBillingContacts(
        user.thirdparty.contactList.filter(
          (contact) => contact.type === AppConstant.typeContactBilling,
        ),
      );

      // Filter tech contacts
      setTechContacts(
        user.thirdparty.contactList.filter(
          (contact) => contact.type === AppConstant.typeContactTech,
        ),
      );
    }

    setLoading(false);
  }, [user.data]);

  return (
    <>
      <Header
        focus={false}
        title={t("MyAccount")}
        titleIcon={<PersonOutlineIcon />}
        titleDesc=""
      >
        {menu.onSmartphone ? (
          <CircleButton
            variant="contained"
            className={classes.btn}
            onClick={handleLogoutClick}
          >
            <LinkIcon />
          </CircleButton>
        ) : (
          <Button
            variant="contained"
            endIcon={<LinkIcon />}
            className={classes.btn}
            onClick={handleLogoutClick}
          >
            {t("Logout")}
          </Button>
        )}
      </Header>
      <Content>
        {/* Main contact section */}
        <ToggleSection
          title={t("MainContact")}
          icon={<StarIcon />}
          description={t("ProfileMainContactDesc")}
          defaultExpanded={true}
        >
          {loading ? (
            <Skeleton variant="rect" height={100} />
          ) : (
            <ProfileMainContact userData={user} />
          )}
        </ToggleSection>

        {/* Your garage section */}
        {!user.individual && (
          <ToggleSection
            title={t("YourGarage")}
            icon={<DriveEtaIcon />}
            description={t("YourGarageDesc")}
            defaultExpanded={true}
          >
            {loading ? (
              <Skeleton variant="rect" height={100} />
            ) : (
              <ProfileCompany thirdparty={user.thirdparty} />
            )}
          </ToggleSection>
        )}

        {/* Billing contact */}
        {!user.individual && (
          <ToggleSection
            title={t("BillingContact")}
            icon={<CreditCardIcon />}
            description={t("ProfileBillingContactDesc")}
            defaultExpanded={true}
          >
            {loading ? (
              <Skeleton variant="rect" height={100} />
            ) : (
              <ProfileBillingContact contacts={billingContacts} />
            )}
          </ToggleSection>
        )}

        {/* Tech contact */}
        {!user.individual && (
          <ToggleSection
            title={t("TechnicalContact")}
            icon={<PeopleIcon />}
            description={t("ProfileTechnicalContactDesc")}
            defaultExpanded={true}
          >
            {loading ? (
              <Skeleton variant="rect" height={100} />
            ) : (
              <ProfileTechnicalContact contacts={techContacts} />
            )}
          </ToggleSection>
        )}

        {/* Language */}
        {langs.length > 1 && (
          <ToggleSection
            title={t("Language")}
            icon={<TranslateIcon />}
            description={t("LanguageDesc")}
            defaultExpanded={true}
          >
            {loading ? (
              <Skeleton variant="rect" height={100} />
            ) : (
              <CotroliaFormControl variant="filled">
                <CotroliaInputLabel>{t("SelectLanguage")}</CotroliaInputLabel>
                <LanguageSelector />
              </CotroliaFormControl>
            )}
          </ToggleSection>
        )}
      </Content>
    </>
  );
};

export default Profile;
