import React, { useEffect, useState } from "react";
import {
  Button,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/core/input/CotroliaLoadingButton";
import { CotroliaStepperStep } from "../../../components/core/stepper/CotroliaStepper";
import CotroliaStepper from "../../../components/core/stepper/CotroliaStepper";
import CotroliaStepperActions from "../../../components/core/stepper/CotroliaStepperActions";
import StepCarCotrolia from "./steps/cotrolia/StepCarCotrolia";
import ContentCard from "../../../components/core/layout/ContentCard";
import { useNewInterventionForm } from "../context/NewInterventionContext";
import NewInterventionStepSkeleton from "../../../components/core/skeleton/NewInterventionStepSkeleton";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useAuth } from "../../user/context/auth-context";
import { useNavigate } from "react-router";
import StepPart from "./steps/StepPart";
import StepShipping from "./steps/StepShipping";
import StepPartCotrolia from "./steps/cotrolia/StepPartCotrolia";
import StepPackaging from "./steps/StepPackaging";
import StepFinish from "./steps/StepFinish";
import StepShippingCotrolia from "./steps/cotrolia/StepShippingCotrolia";
import SendIcon from "@material-ui/icons/Send";
import PackingInstructionsAnimationRepturn from "../animations/packingInstructionsRepturn.lottie";
import PartNumberExampleImageRepturn from "../images/partNumberExampleRepturn.svg";
import PackingInstructionsAnimationCotrolia from "../animations/packingInstructionsCotrolia.lottie";
import PartNumberExampleImageCotrolia from "../images/partNumberExampleCotrolia.svg";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
    marginTop: 6,
  },
}));

interface InterFormStep extends CotroliaStepperStep {
  title: string;
  subtitle: string;
  content: React.ReactNode;
  withSections?: boolean;
}

const InterForm: React.FC = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const { fetchConnectedUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    loading,
    stepIndex,
    setStepIndex,
    intervention,
    config,
    stepState,
    setStepState,
    sendForm,
  } = useNewInterventionForm();

  const steps: InterFormStep[] = [
    {
      key: "StepPart",
      title: t("YourBrokenPart"),
      subtitle: t("YourBrokenPartDesc"),
      label: t("StepPart"),
      content: <StepPart />,
      withSections: true,
    },
    {
      key: "StepPackage",
      title: t("StepPackagingTitle"),
      subtitle: t("StepPackagingDesc"),
      label: t("StepPackage"),
      content: (
        <StepPackaging
          defaultComplementaryPartInfos={t("NoComplementaryPart")}
          srcAnimation={PackingInstructionsAnimationRepturn}
          srcImageExample={PartNumberExampleImageRepturn}
          partNumberTextInfo={t("PartNumberExample")}
        />
      ),
    },
    {
      key: "StepDelivery",
      title: t("StepShippingTitle"),
      subtitle: t("StepShippingDesc"),
      label: t("StepDelivery"),
      content: <StepShipping />,
    },
    {
      key: "StepFinal",
      title: t("StepFinishTitle"),
      subtitle: t("StepFinishDesc"),
      label: t("StepFinal"),
      content: <StepFinish />,
      withSections: true,
    },
  ];

  if (config.sector === "1") {
    steps.splice(0, 0, {
      key: "StepCar",
      title: t("YourBrokenCar"),
      subtitle: t("YourBrokenCarDesc"),
      label: t("StepCar"),
      content: <StepCarCotrolia />,
    });
    steps.splice(1, 1, {
      key: "StepPart",
      title: t("YourBrokenPart"),
      subtitle: t("YourBrokenPartDesc"),
      label: t("StepPart"),
      content: <StepPartCotrolia />,
      withSections: true,
    });
    steps.splice(2, 1, {
      key: "StepPackage",
      title: t("StepPackagingTitle"),
      subtitle: t("StepPackagingDesc"),
      label: t("StepPackage"),
      content: (
        <StepPackaging
          defaultComplementaryPartInfos={t("NoComplementaryPartCotrolia")}
          srcAnimation={PackingInstructionsAnimationCotrolia}
          srcImageExample={PartNumberExampleImageCotrolia}
          partNumberTextInfo={t("PartNumberExampleCotrolia")}
        />
      ),
    });
    steps.splice(3, 1, {
      key: "StepDelivery",
      title: t("StepShippingTitle"),
      subtitle: t("StepShippingDesc"),
      label: t("StepDelivery"),
      content: <StepShippingCotrolia />,
    });
  }

  const [sending, setSending] = useState(false);

  const handlePrevious = () => {
    setStepIndex(stepIndex - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setStepState("tovalidate");
  };

  const handleClickSend = async () => {
    setStepState("tovalidate");
  };

  const send = async () => {
    try {
      setSending(true);
      const uploadErr = await sendForm(intervention);
      // Fetch me to get updated contact list and intervention numbers
      fetchConnectedUser();
      if (uploadErr === 0) {
        // Display feedback and redirect
        const myAlert = alert.show("", {
          title: t("CreateInterSuccess"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as AlertCustomOptions);
        navigate("/");
      } else {
        console.error("Number of files not uploaded : " + uploadErr);
        const myAlert = alert.show(t("FileNotUploadedDesc"), {
          title: t("FileNotUploaded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "warning",
        } as unknown as AlertCustomOptions);
      }
    } catch (err) {
      console.error(err);
      const myAlert = alert.show(t("CreateInterErrorDesc"), {
        title: t("CreateInterError"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "error",
      } as unknown as AlertCustomOptions);
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    if (stepState === "validated") {
      if (stepIndex === steps.length - 1) {
        send();
      } else {
        setStepIndex(stepIndex + 1);
        window.scrollTo(0, 0);
      }
      setStepState("normal");
    }
  }, [stepState]);

  return (
    <>
      <ContentCard
        style={{
          marginBottom: steps[stepIndex].withSections ? 10 : 0,
          padding: 32,
        }}
      >
        <NewInterFormHeader steps={steps} stepIndex={stepIndex} />
        {loading && <NewInterventionStepSkeleton />}

        {!steps[stepIndex].withSections && !loading && (
          <div
            style={{
              paddingTop: theme.spacing(8),
            }}
          >
            {steps[stepIndex].content}
          </div>
        )}
      </ContentCard>
      {steps[stepIndex].withSections && !loading && steps[stepIndex].content}
      <NewInterFormFooter
        steps={steps}
        stepIndex={stepIndex}
        sending={sending}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleSend={handleClickSend}
      />
    </>
  );
};

const NewInterFormHeader: React.FC<{
  steps: InterFormStep[];
  stepIndex: number;
}> = (props) => {
  const classes = useStyles();
  const step = props.steps[props.stepIndex];
  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {step.title}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {step.subtitle}
      </Typography>
      <CotroliaStepper
        steps={props.steps}
        activeStep={props.stepIndex}
        variant="primary"
        style={{ padding: 0, marginTop: 32 }}
      />
    </>
  );
};

const NewInterFormFooter: React.FC<{
  steps: InterFormStep[];
  stepIndex: number;
  sending: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSend: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <CotroliaStepperActions
      style={{
        width: "auto",
        margin: theme.spacing(4),
      }}
    >
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => props.handlePrevious()}
        disabled={props.stepIndex === 0} // We disable the back button if we are in the first step
        style={{ padding: "16px 24px", borderRadius: 6 }}
      >
        {t("GoBack")}
      </Button>
      {props.stepIndex < props.steps.length - 1 && (
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={() => props.handleNext()}
          style={{ padding: "16px 24px", borderRadius: 6 }}
        >
          {t("Continue")}
        </Button>
      )}
      {props.stepIndex === props.steps.length - 1 && (
        <LoadingButton
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={() => props.handleSend()}
          isLoading={props.sending}
          loadingLabel={t("WaitingSendIntervention")}
          originalLabel={t("SendIntervention")}
          style={{ padding: "16px 24px", borderRadius: 6 }}
        >
          {t("SendIntervention")}
        </LoadingButton>
      )}
    </CotroliaStepperActions>
  );
};

export default InterForm;
