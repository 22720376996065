import { Theme, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import configurationService from "../services/configuration.service";
import useCustomTheme, { CustomThemeType } from "../utils/theme";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

type Country = {
  label: string;
  code: string;
};

type CountryList = {
  [key: string]: Country;
};

interface ConfigurationContextType {
  underMaintenance: boolean;
  loading: boolean;
  apiError: boolean;
  themes: CustomThemeType;
  declination: string;
  logoLight: string;
  logoLightSmall: string;
  logoDark: string;
  logoDarkSmall: string;
  name: string;
  langs: { label: string; code: string }[];
  externalUrlToolsMenu: string;
  countries: CountryList;
  sector: string;
  idMatomoTrack: string;
  matomoUrl: string;
  featuredProductUrl: string;
  featuredProductUsername: string;
  featuredProductUsersecret: string;
  fetchConfiguration(): Promise<void>;
}

export const ConfigurationContext =
  React.createContext<ConfigurationContextType>({} as ConfigurationContextType);

const ConfigurationProvider: React.FC = (props) => {
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const themes = useCustomTheme();
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logoLight, setLogoLight] = useState("");
  const [logoLightSmall, setLogoLightSmall] = useState("");
  const [logoDark, setLogoDark] = useState("");
  const [logoDarkSmall, setLogoDarkSmall] = useState("");
  const [declination, setDeclination] = useState("");
  const [name, setName] = useState("");
  const [langs, setLangs] = useState([]);
  const [externalUrlToolsMenu, setExternalUrlToolsMenu] = useState("");
  const [countries, setCountries] = useState({});
  const [sector, setSector] = useState("");
  const [idMatomoTrack, setIdMatomoTrack] = useState("");
  const [matomoUrl, setMatomoUrl] = useState("");
  const [featuredProductUrl, setFeaturedProductUrl] = useState("");
  const [featuredProductUsername, setFeaturedProductUsername] = useState("");
  const [featuredProductUsersecret, setFeaturedProductUsersecret] =
    useState("");
  const { i18n } = useTranslation();

  const prepareThemeWithPalette = (palette: PaletteOptions): Theme => {
    return createTheme({
      palette: palette,
      typography: {
        h4: {
          fontWeight: 500,
        },
        h6: {
          lineHeight: "28px",
        },
      },
    });
  };

  const fetchConfiguration = async () => {
    localStorage.removeItem("externalApiUrl");
    return configurationService
      .getThemeConfiguration(i18n.language)
      .then((res) => {
        if (res.isUnderMaintenance) {
          setUnderMaintenance(true);
        }
        setDeclination(res.themeDeclination);
        switch (res.themeDeclination) {
          case "wmenu_wcontent":
            themes.setMenuTheme(prepareThemeWithPalette(res.lightTheme));
            themes.setTheme(prepareThemeWithPalette(res.lightTheme));
            break;
          case "wmenu_dcontent":
            themes.setMenuTheme(prepareThemeWithPalette(res.lightTheme));
            themes.setTheme(prepareThemeWithPalette(res.darkTheme));
            break;
          case "dmenu_wcontent":
            themes.setMenuTheme(prepareThemeWithPalette(res.darkTheme));
            themes.setTheme(prepareThemeWithPalette(res.lightTheme));
            break;
          case "dmenu_dcontent":
            themes.setMenuTheme(prepareThemeWithPalette(res.darkTheme));
            themes.setTheme(prepareThemeWithPalette(res.darkTheme));
            break;
          default:
            themes.setTheme(createTheme());
            themes.setMenuTheme(createTheme());
            break;
        }
        setLogoLight(res.logoLight);
        setLogoLightSmall(res.logoLightSmall);
        setLogoDark(res.logoDark);
        setLogoDarkSmall(res.logoDarkSmall);
        setName(res.name);
        setLangs(res.availableLangs);
        setExternalUrlToolsMenu(res.externalUrlToolsMenu);
        setCountries(res.availableShippingCountries);
        setSector(res.sector);
        setIdMatomoTrack(res.idMatomoTrack);
        setMatomoUrl(res.matomoUrl);
        setFeaturedProductUrl(res.featuredProductUrl);
        setFeaturedProductUsername(res.featuredProductUsername);
        setFeaturedProductUsersecret(res.featuredProductUsersecret);

        // [#159] Set title and favicon
        document.title = res.title || res.name;
        const faviconEl: HTMLLinkElement | null =
          document.querySelector("#favicon");
        if (faviconEl) {
          faviconEl.href = res.favicon;
        }

        const name = res.title || res.name;

        const manifest = {
          short_name: name,
          name: name,
          icons: [
            {
              src: res.favicon,
              sizes: "any",
              type: "image/png",
            },
            {
              src: "favicon.ico",
              sizes: "144x144 48x48",
              type: "image/png",
            },
          ],
          display: "standalone",
          theme_color: "#000000",
          background_color: "#ffffff",
        };
        const blob = new Blob([JSON.stringify(manifest)], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);

        const manifestEL: HTMLLinkElement | null =
          document.querySelector("#manifest");
        if (manifestEL) {
          manifestEL.setAttribute("href", url);
        }

        if (import.meta.env.PROD && !!res.externalApiUrl) {
          localStorage.setItem("externalApiUrl", res.externalApiUrl);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        throw err;
      });
  };

  useEffect(() => {
    // Get configuration values
    setApiError(false);
    fetchConfiguration();
  }, []);

  return (
    <ConfigurationContext.Provider
      value={{
        underMaintenance,
        loading,
        apiError,
        themes,
        declination,
        logoLight,
        logoDark,
        logoLightSmall,
        logoDarkSmall,
        name,
        langs,
        externalUrlToolsMenu,
        countries,
        sector,
        idMatomoTrack,
        matomoUrl,
        featuredProductUrl,
        featuredProductUsername,
        featuredProductUsersecret,
        fetchConfiguration,
      }}
      {...props}
    >
      {props.children}
    </ConfigurationContext.Provider>
  );
};

const useConfiguration = () => React.useContext(ConfigurationContext);

export { ConfigurationProvider, useConfiguration };
