import React, { useState } from "react";
import {
  ContentTwoSided,
  LeftSide,
  RightSide,
} from "../../../../components/core/ContentTwoSided";
import { useNewInterventionForm } from "../../context/NewInterventionContext";
import FieldFrequency from "../fields/common/FieldFrequency";
import FieldWarranty from "../fields/part/FieldWarranty";
import FieldPartType from "../fields/part/FieldPartType";
import FieldManufacturer from "../fields/part/FieldManufacturer";
import FieldReference from "../fields/part/FieldReference";
import FieldDescription from "../fields/part/FieldDescription";
import FieldPartPictures from "../fields/part/FieldPartPictures";
import { useStepValidator } from "../../hooks/useStepValidator";
import ToggleSection from "../../../../components/core/ToggleSection";
import { useTranslation } from "react-i18next";
import MemoryIcon from "@material-ui/icons/Memory";
import BuildIcon from "@material-ui/icons/Build";
import { WarrantyValue } from "../../types/NewInterventionFormTypes";
import interventionService from "../../../../services/intervention.service";

interface StepPartErrors {
  typeError?: string;
  manufacturerError?: string;
  warrantyPrevInterError?: string;
  refError?: string;
  descriptionError?: string;
}

const StepPart: React.FC = () => {
  const { t } = useTranslation();
  const { intervention, setIntervention } = useNewInterventionForm();
  const [errors, setErrors] = useState<StepPartErrors>({});

  useStepValidator(() => {
    const stepErrors: StepPartErrors = {
      typeError:
        !intervention.part.type || intervention.part.type === "0"
          ? t("RequiredFieldTypeError")
          : undefined,
      warrantyPrevInterError:
        intervention.part.waranty === WarrantyValue.Yes &&
        !intervention.part.warrantyPrevInter
          ? t("RequiredFieldWarrantyPrevInterError")
          : undefined,
      manufacturerError: !intervention.part.manufacturer
        ? t("RequiredFieldManufacturerError")
        : undefined,
      descriptionError: !intervention.part.description
        ? t("RequiredFieldDescriptionError")
        : undefined,
      refError:
        !intervention.part.ref || !intervention.part.ref.ref
          ? t("RequiredFieldRefError")
          : undefined,
    };
    setErrors(stepErrors);
    return (
      !stepErrors.typeError &&
      !stepErrors.warrantyPrevInterError &&
      !stepErrors.manufacturerError &&
      !stepErrors.descriptionError &&
      !stepErrors.refError
    );
  });

  return (
    <div>
      <ToggleSection
        title={t("PartSectionType")}
        description={t("PartSectionTypeInfo")}
        icon={<MemoryIcon />}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldWarranty
              error={errors.warrantyPrevInterError}
              onChange={(waranty, warrantyPrevInter) => {
                if (waranty === WarrantyValue.Yes && warrantyPrevInter?.id) {
                  interventionService
                    .getIntervention(warrantyPrevInter.id)
                    .then((res) => {
                      setIntervention({
                        ...intervention,
                        part: {
                          ...intervention.part,
                          waranty: waranty,
                          warrantyPrevInter: warrantyPrevInter,
                          type: res.partType?.id,
                          ref: res.partRef,
                          manufacturer: res.manufacturer,
                        },
                      });
                    })
                    .catch(() => {
                      console.error(
                        "Can’t get intervention information with id : " +
                          warrantyPrevInter.id,
                      );
                    });
                }
                if (intervention.part.warrantyPrevInter && !warrantyPrevInter) {
                  setIntervention({
                    ...intervention,
                    part: {
                      ...intervention.part,
                      waranty: waranty,
                      warrantyPrevInter: undefined,
                      type: undefined,
                      ref: undefined,
                      manufacturer: undefined,
                    },
                  });
                }
                setErrors({
                  ...errors,
                  warrantyPrevInterError: undefined,
                  typeError: undefined,
                  refError: undefined,
                  manufacturerError: undefined,
                });
              }}
            />
            <FieldPartType
              error={errors.typeError}
              onChange={() => {
                setErrors({ ...errors, typeError: undefined });
              }}
            />
            <FieldManufacturer
              error={errors.manufacturerError}
              onChange={() => {
                setErrors({ ...errors, manufacturerError: undefined });
              }}
            />
          </LeftSide>

          <RightSide>
            <FieldReference
              error={errors.refError}
              onChange={() => {
                setErrors({ ...errors, refError: undefined });
              }}
            />
            <FieldPartPictures />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>
      <ToggleSection
        title={t("PartSectionFailureDesc")}
        description={t("PartSectionFailureInfo")}
        icon={<BuildIcon />}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldFrequency />
          </LeftSide>
          <RightSide>
            <FieldDescription
              error={errors.descriptionError}
              onChange={() =>
                setErrors({ ...errors, descriptionError: undefined })
              }
            />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>
    </div>
  );
};

export default StepPart;
