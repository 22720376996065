import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import FilterImg from "../../assets/img/search.svg";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

const NoFilterResult = () => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.root}>
        <img src={FilterImg} alt="No results" height={300} />
        <Box className={classes.text}>
          <Typography variant="h6">{t("ListNoResult")}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default NoFilterResult;
