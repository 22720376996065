import API from "./api";

/**
 * Class to manage authentication
 */
class AuthService {
  constructor() {
    const api = new API();
    this.api = api;
  }

  successCallback = (status, data) => {
    return { status, data };
  };

  /**
   * Method used to login to the dolibarr
   *
   * @param string      Email
   * @param string      Password
   */
  login = async (email, password) => {
    let formData = new FormData();
    formData.append("login", email);
    formData.append("password", password);
    const { data } = await this.api
      .post("/supercotrolia/login", formData, this.successCallback)
      .catch((err) => {
        // We can switch err.response.status for more details
        throw err;
      });
    return data;
  };

  /**
   * Method used to logout from the dolibarr
   */
  logout = async () => {
    const response = await this.api
      .post("/logout", {}, this.successCallback)
      .catch((err) => {
        throw err;
      });

    return response;
  };

  /**
   * Method used to register on the dolibarr
   *
   * @param {CotroliaUserRegisterModel} datas
   * @returns
   */
  register = async (datas) => {
    const response = await this.api
      .post("/supercotrolia/register", datas, this.successCallback)
      .catch((err) => {
        throw err;
      });

    return response;
  };

  /**
   * Method used to get connected user informations
   */
  getMe = async () => {
    const response = await this.api
      .get("/supercotrolia/me", this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response.data;
  };

  /**
   * Method used to send reset password link to the user with corresponding email
   */
  getResetPasswordLink = async (email) => {
    const response = await this.api
      .get(
        "/supercotrolia/reset-password-link?email=" + email,
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Method used to reset user password
   */
  postResetPassword = async (token, password) => {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("password", password);
    const response = await this.api
      .post("/supercotrolia/reset-password", formData, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Get the list of users for a thirdparty
   *
   * @param {int} socid
   * @returns
   */
  getUsers = async (socid) => {
    const response = await this.api
      .get("/supercotrolia/users?socid=" + socid, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Update user informations
   *
   * @param {string} lastname   Lastname
   * @param {string} firstname  Firstname
   * @param {string} email      Email
   * @returns
   */
  updateMe = async (lastname, firstname, email) => {
    const response = await this.api
      .put(
        "/supercotrolia/me",
        { lastname, firstname, email },
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Update user language
   *
   * @param {string} languageCode   Language code
   * @returns
   */
  updateLanguage = async (languageCode) => {
    const response = await this.api
      .put("/supercotrolia/me/language", { languageCode }, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Update user company informations
   *
   * @param {string} name     Company name
   * @param {string} phone    Company phone
   * @param {string} vat      Company vat number
   * @returns
   */
  updateCompany = async (name, phone, address, vat) => {
    const response = await this.api
      .put(
        "/supercotrolia/me/company",
        { name, phone, address, vat },
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Delete a contact role
   *
   * @param {number} id     Contact id
   * @param {string} role   Role code
   * @returns
   */
  deleteContactRole = async (id, role) => {
    const response = await this.api
      .delete(
        `/supercotrolia/me/contact/${id}/role/${role}`,
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Add a contact role
   *
   * @param {number} id     Contact id
   * @param {string} role   Role code
   * @returns
   */
  addContactRole = async (id, role) => {
    const response = await this.api
      .post(
        `/supercotrolia/me/contact/${id}/role/${role}`,
        {},
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Delete a contact
   *
   * @param {number} id     Contact id
   * @returns
   */
  deleteContact = async (id) => {
    const response = await this.api
      .delete(`/supercotrolia/me/contact/${id}`, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Update a contact
   *
   * @param {number}  id            Contact id
   * @param {string}  lastname      Lastname
   * @param {string}  firstname     Firstname
   * @param {string}  email         Email
   * @param {string}  phone         Phone
   *
   * @returns
   */
  updateContact = async (id, lastname, firstname, email, phone) => {
    const response = await this.api
      .put(
        `/supercotrolia/me/contact/${id}`,
        {
          lastname,
          firstname,
          phone,
          email,
        },
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Create a contact
   *
   * @param {string}  lastname    Lastname
   * @param {string}  firstname   Firstname
   * @param {string}  email       Email
   * @param {string}  phone       Phone number
   * @param {string}  type        Contact type
   * @returns
   */
  createContact = async (lastname, firstname, email, phone, type) => {
    const response = await this.api
      .post(
        `/supercotrolia/me/contact/`,
        {
          lastname,
          firstname,
          phone,
          email,
          type,
        },
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Create an account for a contact
   *
   * @param {number}  id        Contact id
   * @param {string}  language  Laguage code
   * @returns
   */
  createAccount = async (id, language) => {
    const response = await this.api
      .post(
        `/supercotrolia/me/contact/${id}/user`,
        { language },
        this.successCallback,
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Delete an account
   *
   * @param {number}  id    User id
   * @returns
   */
  deleteAccount = async (id) => {
    const response = await this.api
      .delete(`/users/${id}`, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response;
  };
}

export default new AuthService();
