import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const HeaderActions = (props) => {
  const classes = useStyle();
  return <Box className={classes.root}>{props.children}</Box>;
};

export default HeaderActions;
