import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import DividerWithIcon from "./DividerWithAction";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useConfiguration } from "../../context/configuration-context";
import { encode } from "js-base64";

const useStyle = makeStyles((theme) => ({
  listContainer: {
    display: "flex",
    boxSizing: "border-box",
    height: "100%",
    borderRadius: "0",
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    margin: theme.spacing(0, -15, -4, -15),
    padding: theme.spacing(4, 15, 0, 15),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, -10, -3, -10),
      padding: theme.spacing(3, 10, 0, 10),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, -2, -3, -2),
      padding: theme.spacing(3, 2, 0, 2),
    },
  },
  listTitle: {
    textAlign: "center",
    opacity: 0.87,
    marginTop: theme.spacing(2),
  },
  listContent: {
    width: "100%",
    zIndex: theme.zIndex.drawer,
    display: "flex",
    flexDirection: "column",
  },

  cardScroll: {
    overflowX: "scroll",
    margin: theme.spacing(2, -15, 4, -15),
    padding: theme.spacing(0, 15, 2, 15),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, -10, 4, -10),
      padding: theme.spacing(0, 10, 2, 10),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, -2, 4, -2),
      padding: theme.spacing(0, 2, 2, 2),
    },
  },

  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
    gridAutoRows: "min-content",
    gap: "16px",
  },
}));

interface News {
  id: number;
  name: string;
  permalink: string;
  images: { src: string }[];
  categories: { name: string }[];
}

const CotroliaNewsDashboardList: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [news, setNews] = useState<News[]>();
  const configuration = useConfiguration();

  useEffect(() => {
    const headers = new Headers();
    headers.set(
      "Authorization",
      "Basic " +
        encode(
          configuration.featuredProductUsername +
            ":" +
            configuration.featuredProductUsersecret,
        ),
    );
    fetch(configuration.featuredProductUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status.toString());
        }
      })
      .then((datas) => setNews(datas.slice(0, 6)))
      .catch((error) => console.log("unable to fetch news : " + error));
  }, []);

  const handleAction = () => {
    window.open(configuration.externalUrlToolsMenu, "_blank");
  };

  return (
    <>
      {news && news.length > 0 && (
        <div className={classes.listContainer}>
          <div className={classes.listContent}>
            <DividerWithIcon
              title={t("WhatsNew")}
              icon={<ArrowForwardIcon />}
              action={handleAction}
              actionName={new URL(
                configuration.externalUrlToolsMenu,
              ).hostname.replace("www.", "")}
            />

            <Typography variant="h5" className={classes.listTitle}>
              {t("newsTitle", { name: configuration.name })}
            </Typography>

            <div className={classes.cardScroll}>
              <div className={classes.cardContainer}>
                {news.map((n) => (
                  <NewsItemCard key={n.id} newsItem={n} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const NewsItemCard: React.FC<{ newsItem: News }> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = makeStyles({
    card: {
      height: 284,
      border: "1px solid black",
      borderColor: theme.palette.divider,
      padding: theme.spacing(2),
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.20)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        height: "auto",
      },
    },

    cardContent: {
      position: "relative",
    },

    tag: {
      position: "absolute",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      padding: "0 8px",
      lineHeight: "26px",
      borderRadius: 13,
    },

    cardImg: {
      width: "100%",
      height: 176,
      objectFit: "contain",
    },

    cardName: { marginTop: theme.spacing(2) },

    cardSubtitle: { marginTop: theme.spacing(1), opacity: 0.7 },
  })();
  return (
    <Card
      className={classes.card}
      elevation={0}
      onClick={() => window.open(props.newsItem.permalink, "_blank")}
    >
      <Grid className={classes.cardContent} container direction="column">
        <Typography variant="subtitle2" className={classes.tag}>
          {t("NewsTagNew")}
        </Typography>
        <img className={classes.cardImg} src={props.newsItem.images[0].src} />
        <Typography variant="subtitle2" className={classes.cardName}>
          {props.newsItem.name}
        </Typography>
        <Typography variant="caption" className={classes.cardSubtitle}>
          {props.newsItem.categories.map((c) => c.name).join(", ")}
        </Typography>
      </Grid>
    </Card>
  );
};

export default CotroliaNewsDashboardList;
