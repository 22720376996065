import React, { useState } from "react";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import authService from "../../../services/auth.service";
import { useConfiguration } from "../../../context/configuration-context";
import axios from "axios";
import { User } from "../types/userTypes";
import { Outlet, useNavigate } from "react-router-dom";
import { useSentry } from "../../../hooks/SentryHook";

interface AuthContextType {
  user?: User;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  fetchConnectedUser: () => Promise<void>;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType,
);

const AuthProvider: React.FC = (props) => {
  const [user, setUser] = useState<User>();
  const { t, i18n } = useTranslation();
  const { setupSentryUser } = useSentry();
  const navigate = useNavigate();
  const alert = useAlert();
  const { fetchConfiguration } = useConfiguration();

  const fetchConnectedUser = async () => {
    await authService
      .getMe()
      .then(async (user: User) => {
        setUser(user);
        setupSentryUser(user);
        const userLang = user.language;
        // [#178] Change connected user language
        if (userLang && i18n.language !== userLang) {
          await i18n.changeLanguage(userLang);
          await fetchConfiguration(); // Refetch configuration in the user language
        }
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          throw error;
        }
      });
  };

  const login = async (email: string, password: string) => {
    return authService
      .login(email, password)
      .then(() => fetchConnectedUser())
      .catch((err) => {
        let errMsg = t("InternalError");
        if (axios.isAxiosError(err) && err.response && err.response.status) {
          const status = err.response.status;
          if (status === 403) {
            // Access denied
            errMsg = t("WrongLoginOrPassword");
          }
        }
        const myAlert = alert.show(errMsg, {
          title: t("Error"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  // Logout
  const logout = async () => {
    try {
      await authService.logout().finally(() => {
        setUser(undefined);
        setupSentryUser(undefined);
        navigate("/login", { state: undefined });
      });
    } catch (err) {
      // TODO : handle errors
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        fetchConnectedUser,
        setUser,
      }}
      {...props}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
