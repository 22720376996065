import React from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { FormHelperText, Grid, useTheme } from "@material-ui/core";
import InterFormControl from "../../InterFormControl";
import CotroliaInputLabel from "../../../../../components/core/input/CotroliaInputLabel";
import CotroliaSwitch from "../../../../../components/core/input/CotroliaSwitch";
import CotroliaAutocomplete from "../../../../../components/core/input/CotroliaAutocomplete";
import {
  PartWarrantyPrevInter,
  WarrantyValue,
} from "../../../types/NewInterventionFormTypes";
import interventionService from "../../../../../services/intervention.service";

interface FieldWarrantyPropsType {
  error?: string;
  onChange?: (
    waranty: WarrantyValue,
    warrantyPrevInter?: PartWarrantyPrevInter,
  ) => void;
}

const FieldWarranty: React.FC<FieldWarrantyPropsType> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { intervention, setIntervention } = useNewInterventionForm();

  return (
    <Grid
      container
      item
      style={{
        width: "100%",
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(1.5),
        borderRadius: 10,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          flexWrap: "nowrap",
        }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="space-between"
          style={{ paddingRight: theme.spacing(1) }}
        >
          <CotroliaInputLabel
            id="waranty-label"
            shrink={false}
            variant="standard"
            style={{
              position: "relative",
              margin: 0,
              transform: "unset",
              lineHeight: "24px",
            }}
          >
            {t("Waranty")}
          </CotroliaInputLabel>
          <FormHelperText
            id="waranty-helper"
            style={{
              margin: 0,
              lineHeight: "20px",
              fontSize: 12,
              fontWeight: 400,
              color: theme.palette.text.primary,
              opacity: 0.7,
            }}
          >
            {t("WarantyHelper")}
          </FormHelperText>
        </Grid>
        <Grid>
          <CotroliaSwitch
            id="waranty"
            aria-describedby="waranty-helper"
            checked={intervention.part.waranty === WarrantyValue.Yes}
            defaultChecked={intervention.part.waranty === WarrantyValue.Yes}
            onChange={(_e, value: boolean) => {
              const warranty = value ? WarrantyValue.Yes : WarrantyValue.No;
              const warrantyPrevInter = value
                ? intervention.part.warrantyPrevInter
                : undefined;
              setIntervention({
                ...intervention,
                part: {
                  ...intervention.part,
                  waranty: warranty,
                  warrantyPrevInter: warrantyPrevInter,
                },
              });
              props.onChange && props.onChange(warranty, warrantyPrevInter);
            }}
          />
        </Grid>
      </Grid>
      {intervention.part.waranty === WarrantyValue.Yes && (
        <InterFormControl
          variant="filled"
          required
          error={!!props.error}
          style={{ margin: `${theme.spacing(2)}px 0 0 0` }}
        >
          <CotroliaAutocomplete
            id="waranty-prev-inter"
            label={t("WarantyPrevInter")}
            value={intervention.part.warrantyPrevInter ?? { id: -1, label: "" }}
            required
            onChange={(prevInter?: PartWarrantyPrevInter) => {
              props.onChange &&
                props.onChange(intervention.part.waranty, prevInter);
            }}
            getOptions={(searchValue: string) =>
              interventionService
                .listOldIntervention(searchValue)
                .then((res) => {
                  const ret: PartWarrantyPrevInter[] = [];
                  Object.keys(res).forEach((key) => {
                    ret.push({ id: key, label: res[key] });
                  });
                  return ret;
                })
            }
            getOptionLabel={(option: PartWarrantyPrevInter) => {
              return option?.label ?? "";
            }}
            error={!!props.error}
            nested
          />

          <FormHelperText
            id="waranty-prev-inter-helper"
            style={{
              margin: 0,
              padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
            }}
          >
            {props.error ?? t("WarantyPrevInterHelper")}
          </FormHelperText>
        </InterFormControl>
      )}
    </Grid>
  );
};

export default FieldWarranty;
