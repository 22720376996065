import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import authService from "../../services/auth.service";
import { useTheme } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    background: theme.palette.paper,
    width: "30vw",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      alignSelf: "unset",
    },
  },
  title: {
    margin: `${theme.spacing(2)}px 0`,
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  link: {
    width: "100%",
    textDecoration: "none",
  },
}));

const ForgetPasswordForm = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState(queryParams.get("email"));

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleSendResetLink = async () => {
    try {
      await authService.getResetPasswordLink(email);
      alert.show(t("SuccessResetPasswordLinkSendDesc"), {
        title: t("SuccessResetPasswordLinkSend"),
        close: () => {
          alert.remove(alert);
        },
        type: "success",
      });
      // Redirect to login page
      navigate("/login");
    } catch (err) {
      console.error(err);
      alert.show(t("ErrorResetPasswordLinkSendDesc"), {
        title: t("ErrorResetPasswordLinkSend"),
        close: () => {
          alert.remove(alert);
        },
        type: "error",
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {t("PasswordForgotten")}
      </Typography>

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        }}
      >
        {t("ResetPasswordInfo")}
      </Typography>

      <FormControl className={classes.inputContainer}>
        <TextField
          id="email"
          label={t("YourEmail")}
          variant="filled"
          color="secondary"
          aria-describedby="email-helper"
          onChange={(e) => handleEmailChange(e)}
          value={email}
        />
        <FormHelperText id="email-helper">
          {t("YourEmailHelperLogin")}
        </FormHelperText>
      </FormControl>

      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={<ArrowForwardIcon />}
        onClick={handleSendResetLink}
      >
        {t("ReceiveResetPasswordLink")}
      </Button>

      <Link to="/login" className={classes.link}>
        <Button color="secondary" className={classes.btn}>
          {t("Cancel")}
        </Button>
      </Link>
    </Box>
  );
};

export default ForgetPasswordForm;
