import { Grid, useTheme } from "@material-ui/core";
import React from "react";

const Side: React.FC = (props) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6}>
      <Grid container item style={{ gap: theme.spacing(3) }}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export const ContentTwoSided: React.FC = (props) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={theme.breakpoints.down("sm") ? 3 : 4}
      justifyContent="space-between"
    >
      {props.children}
    </Grid>
  );
};

export const LeftSide = Side;
export const RightSide = Side;
export default ContentTwoSided;
