import React from "react";

import { Button, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaStepper from "../../core/stepper/CotroliaStepper";
import CotroliaStepperActions from "../../core/stepper/CotroliaStepperActions";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../../core/input/CotroliaInputLabel";
import CotroliaSelect from "../../core/input/CotroliaSelect";
import AppConstant from "../../../utils/appConstant";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
}));

const StepChoice = ({
  previousStep,
  steps,
  activeStep,
  choice,
  setChoice,
  handleSend,
  data,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("ProposalChoice")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("ProposalChoiceDesc")}
      </Typography>
      <CotroliaStepper
        steps={steps}
        activeStep={activeStep}
        variant="secondary"
      />
      <CotroliaFormControl variant="filled" required>
        <CotroliaInputLabel id="choice-label">{t("Choice")}</CotroliaInputLabel>
        <CotroliaSelect
          id="choice"
          labelId="choice-label"
          value={choice}
          onChange={(e) => {
            setChoice(e.target.value);
          }}
        >
          {/* [#169] Verification with type of proposal , in case type is verification only yes choice is displayed */}
          <MenuItem value={AppConstant.proposalChoiceYes}>
            {t("ProposalChoiceYes")}
          </MenuItem>
          {data.propal_type !== AppConstant.proposalTypeVerification && (
            <MenuItem value={AppConstant.proposalChoiceNo}>
              {t("ProposalChoiceNo")}
            </MenuItem>
          )}
          {data.propal_type !== AppConstant.proposalTypeVerification &&
            data.propal_type !== AppConstant.proposalTypeCannotRepair && (
              <MenuItem value={AppConstant.proposalChoiceReturn}>
                {t("ProposalChoiceBack")}
              </MenuItem>
            )}
        </CotroliaSelect>
      </CotroliaFormControl>
      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          color="secondary"
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("Previous")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            handleSend();
          }}
          disabled={choice === ""}
        >
          {t("Validate")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default StepChoice;
