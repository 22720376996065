import React from "react";
import CompanyLogo from "../core/CompanyLogo";
import { useTheme } from "@material-ui/styles";
import { Box, Container, makeStyles } from "@material-ui/core";
import ResetPasswordForm from "../auth/ResetPasswordForm";
import { useConfiguration } from "../../context/configuration-context";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      padding: "0",
      margin: "0",
      display: "block",
    },
  },
  container: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const ResetPassword = () => {
  const classes = useStyle();
  const theme = useTheme();
  const configuration = useConfiguration();
  const declination =
    configuration.declination.indexOf("dcontent") !== -1 ? "light" : "dark";

  return (
    <Container className={classes.root}>
      <Box className={classes.container}>
        <Box style={{ marginBottom: theme.spacing(3) }}>
          <CompanyLogo declination={declination} />
        </Box>
        <ResetPasswordForm />
      </Box>
    </Container>
  );
};

export default ResetPassword;
