import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import configurationService from "../../../../../services/configuration.service";
import WarningIcon from "@material-ui/icons/Warning";

const useStyle = makeStyles((theme) => ({
  background: {
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(3),
  },
  scrollbarComplementaryPart: {
    overflowY: "scroll",
    maxHeight: 320,
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "100%",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      borderRadius: "100vh",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "100vh",
    },
  },
  content: {
    fontFamily: "Roboto, sans-serif",
    color: theme.palette.info.contrastText,
    lineHeight: "24px",
    "& > * > a": {
      color: theme.palette.info.contrastText,
    },
  },
}));

const FieldComplementaryPartInfos: React.FC<{
  defaultInfos?: string;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();
  const { intervention, setIntervention } = useNewInterventionForm();

  const [isLoadingComplementaryPartInfos, setLoadingComplementaryPartInfos] =
    useState(false);

  useEffect(() => {
    if (props.defaultInfos) {
      setIntervention({
        ...intervention,
        packaging: {
          ...intervention.packaging,
          complementaryPartInfos: props.defaultInfos,
        },
      });
    }
    setLoadingComplementaryPartInfos(true);
    configurationService
      .getPackingInstructions(
        intervention.part.type,
        intervention.car.carBrand?.id,
        intervention.car.carModel?.id,
        i18n.language,
      )
      .then((res: string | string[] | undefined) => {
        if (res) {
          setIntervention({
            ...intervention,
            packaging: {
              ...intervention.packaging,
              complementaryPartInfos: typeof res === "string" ? res : res[0],
            },
          });
        }
      })
      .catch((error: unknown) => {
        console.error("Error fetching complementary part instructions:", error);
      })
      .finally(() => setLoadingComplementaryPartInfos(false));
  }, [intervention.part.ref?.id]);
  return (
    <>
      {isLoadingComplementaryPartInfos && <CircularProgress size={25} />}
      {!isLoadingComplementaryPartInfos &&
        !!intervention.packaging.complementaryPartInfos && (
          <Box className={classes.background}>
            <Grid
              container
              justifyContent="space-between"
              style={{ flexWrap: "nowrap", marginBottom: theme.spacing(3) }}
            >
              <Typography
                variant="h5"
                style={{ color: theme.palette.secondary.contrastText }}
              >
                {t("ComplementaryPart")}
              </Typography>
              <WarningIcon style={{ width: 48, height: 48 }} />
            </Grid>
            <Box className={classes.scrollbarComplementaryPart}>
              <Box className={classes.content}>
                {!!intervention.packaging.complementaryPartInfos && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        intervention.packaging.complementaryPartInfos.replace(
                          /(\r\n|\r|\n)/g,
                          "<br>",
                        ),
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
};

export default FieldComplementaryPartInfos;
