import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    height: `calc(100% - ${theme.spacing(6)}px)`, // Height without padding top and bottom
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(4)}px)`, // Height without padding top and bottom
    },
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[900],
    boxShadow: "0px 5px 15px 0px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
  },

  header: {
    color: theme.palette.text.primary,
    padding: 0,
  },
  headerAction: {
    margin: 0,
    alignSelf: "center",
  },

  content: {
    margin: theme.spacing(3, 0, 3, 0),
    padding: 0,
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },

  footer: {
    justifyContent: "center",
    color: theme.palette.text.secondary,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },

  dividerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  dividerLine: {
    border: "1px solid #D5D5D5",
    height: 0,
    flexGrow: "1",
    margin: "0 10px 0 10px",
  },
}));

const InterventionCard = ({
  children,
  className,
  interId,
  interRef,
  icon,
  footer,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();

  return (
    <Card className={`${classes.root} ${className}`}>
      <CardHeader
        classes={{ root: classes.header, action: classes.headerAction }}
        title={
          <Button
            onClick={() => {
              navigate("/intervention/" + interId);
            }}
          >
            <Typography variant="h6">{interRef}</Typography>
          </Button>
        }
        action={icon}
      />
      <CardContent classes={{ root: classes.content }}>{children}</CardContent>
      <CardActions classes={{ root: classes.footer }}>
        <Typography variant="caption">{footer}</Typography>
      </CardActions>
    </Card>
  );
};

export const InterventionCardContentLine = ({ left, right }) => {
  const classes = useStyle();
  return (
    <Box className={classes.dividerContainer}>
      <Typography variant="overline">{left}</Typography>
      <span className={classes.dividerLine}></span>
      <Typography variant="overline">{right}</Typography>
    </Box>
  );
};

export default InterventionCard;
